import styled from 'styled-components'

export const ContentContainer = styled.div`
  width: 100%;
  padding: 8px;

  @media (min-width: 768px) {
    padding: 16px;
  }
`
