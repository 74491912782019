import { Icon } from '@yes.technology/react-toolkit'

import { StyledHierarchyCard } from '../Cockpit/Cockpit.styles'
import { DataOrigin, FilterDate } from './AppliedFilters.styles'
import { useTranslation } from 'react-i18n-lite'

export type AppliedFiltersProps = {
  referencePeriodStartISO?: string
  referencePeriodEndISO?: string
  referenceDateISO?: string
}

const AppliedFilters = ({
  referenceDateISO,
  referencePeriodEndISO,
  referencePeriodStartISO
}: AppliedFiltersProps) => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const firstDayOfYear = new Date(currentYear, 0, 1)
  const lastDayOfYear = new Date(currentYear, 11, 31)
  const today = new Date()

  const referenceDate = referenceDateISO ? new Date(referenceDateISO) : today
  const referencePeriodStart = referencePeriodStartISO
    ? new Date(referencePeriodStartISO)
    : firstDayOfYear
  const referencePeriodEnd = referencePeriodEndISO
    ? new Date(referencePeriodEndISO)
    : lastDayOfYear

  return (
    <StyledHierarchyCard>
      <DataOrigin>
        <h1>{t('cockpit.filter.data-origin-title')}</h1>
        <FilterDate>
          <p>
            <Icon iconName='FiltersFill' icSize='extraSmall' />
            {t('cockpit.filter.reference-period')} ≥{' '}
            {referencePeriodStart.toLocaleDateString()}
          </p>
          <p>
            {t('cockpit.filter.reference-period')} ≤{' '}
            {referencePeriodEnd.toLocaleDateString()}
          </p>
          <p>
            {t('cockpit.filter.reference-date')} ={' '}
            {referenceDate.toLocaleDateString()}
          </p>
        </FilterDate>
      </DataOrigin>
    </StyledHierarchyCard>
  )
}

export default AppliedFilters
