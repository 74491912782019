import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { AnimationSliderContainerType } from './AnimationSlider.types'
import { Col, Row } from '@yes.technology/react-toolkit'
import { StyledGrid } from 'shared/style/grid'
import AnimationSlider from './AnimationSlider'

const AnimationSliderContainer = ({ props }: AnimationSliderContainerType) => {
  const styleProps = useStyleMaps(props.styleMap)

  return (
    <StyledGrid style={styleProps}>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={12}>
          <AnimationSlider {...props} />
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default AnimationSliderContainer
