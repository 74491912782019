import { Icon, Text } from '@yes.technology/react-toolkit'
import { SliderButton, TitleContainer } from './AnimationSlider.styles'
import { useTranslation } from 'react-i18n-lite'

type NavigationSliderProps = {
  length: number
  currentIndex: number
  onPrevClick: () => void
  onNextClick: () => void
  color?: string
  textColor?: string
}

const prependZeroToSingleDigitNumber = (number: number) =>
  new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  }).format(number)

const NavigationSlider = ({
  length,
  currentIndex,
  onPrevClick,
  onNextClick,
  color,
  textColor
}: NavigationSliderProps) => {
  const { t } = useTranslation()

  return (
    <TitleContainer color={color} textColor={textColor}>
      <Text variant='content-04'>{t('animation-slider.slider')} </Text>
      <SliderButton
        onClick={onPrevClick}
        type='button'
        aria-label={t('navigation-slider.previous')}
        disabled={currentIndex === 1}
      >
        <Icon
          iconName={
            currentIndex === 1 ? 'NavigationLeftDisabled' : 'NavigationLeft'
          }
          icSize={currentIndex === 1 ? 'semiSmall' : 'medium'}
          icColor='white'
        />
      </SliderButton>
      <span>
        <Text variant='content-04'>
          Passo {prependZeroToSingleDigitNumber(currentIndex)}
        </Text>
        <Text variant='content-emphasis-04'>
          /{prependZeroToSingleDigitNumber(length)}
        </Text>
      </span>
      <SliderButton
        onClick={onNextClick}
        type='button'
        aria-label={t('navigation-slider.next')}
        disabled={length === currentIndex}
      >
        <Icon
          iconName={
            length === currentIndex
              ? 'NavigationRightDisabled'
              : 'NavigationRight'
          }
          icSize={length === currentIndex ? 'semiSmall' : 'medium'}
          icColor='white'
        />
      </SliderButton>
    </TitleContainer>
  )
}

export default NavigationSlider
