import {
  FilterQuery,
  SaF,
  SaFLegend,
  SkeletonDots
} from '@yes.technology/react-toolkit'
import { useEffect, useId } from 'react'
import useSitemodel from 'site/Render/hooks/useSitemodel'
import SectionDivider from '../SectionDivider'
import { useSiteState } from 'siteState/shared'
import useSaFQuery from './useSaFQuery'
import AppliedFilters, {
  AppliedFiltersProps
} from '../AppliedFilters/AppliedFilters'
import { LegendsContainer } from './SafChartFilter.styles'

type SaFChartFilterProps = {
  props: {
    static_input?: React.ComponentProps<typeof SaF>
    static_dates?: AppliedFiltersProps
  }
}

export default function SaFChartFilter({
  props: { static_input: staticInput, static_dates }
}: SaFChartFilterProps) {
  const sitemodel = useSitemodel()

  const title = sitemodel.title || ''

  const sectionId = useId()

  const [filterQuery] = useSiteState<FilterQuery | undefined>('filter-query')

  const { isFetching, data, error } = useSaFQuery({
    filterQuery: staticInput ? undefined : filterQuery
  })

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  const input = staticInput || data

  if (!filterQuery || (!staticInput && !isFetching && !data)) {
    return null
  }

  return (
    <SectionDivider
      id={sectionId}
      title={title}
      centerHeaderElement={<>SaF Chart</>}
      backgroundColor='#004099'
    >
      {input ? (
        <>
          <SaF {...input} />
          <LegendsContainer>
            <SaFLegend />
            <AppliedFilters {...static_dates} />
          </LegendsContainer>
        </>
      ) : (
        <SkeletonDots size={12} className='justify-content-center' />
      )}
    </SectionDivider>
  )
}
