import { Button, CollapsibleCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const TitleContainer = styled.div<{
  color?: string
  textColor?: string
}>`
  background-color: ${({ color }) => color || 'rgb(0, 107, 255)'};
  color: ${({ textColor }) => textColor || '#FFFFFF'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 16px;
  gap: 4px;

  @media (min-width: 992px) {
    margin-bottom: 20px;
  }
`

export const CollapsibleSection = styled(CollapsibleCard)`
  > div {
    border-style: hidden;
  }
`

export const SlideTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
`

export const SlideNumber = styled.span<{ color?: string; textColor?: string }>`
  width: 32px;
  height: 32px;
  background-color: ${({ color }) => color || 'rgb(0, 107, 255)'};
  border-radius: 50%;
  align-content: center;
  text-align: center;

  > span {
    color: ${({ textColor }) => textColor || '#FFFFFF'};
  }

  @media (min-width: 992px) {
    min-width: 42px;
    min-height: 42px;
  }
`

export const Container = styled.div`
  position: relative;

  @media (min-width: 992px) {
    display: flex;
  }
`

export const CardContainer = styled.div`
  @media (min-width: 992px) {
    flex-basis: 442px;

    > div > div {
      min-height: 700px;
    }
  }
`

export const ImageWrapper = styled.div`
  img {
    width: 100%;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
    flex-shrink: 3;
  }
`

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
`

export const SubtitleWrapper = styled.div`
  margin-top: 16px;
`

export const SliderButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  align-content: center;
  background: rgba(255, 255, 255, 0.75);
  justify-items: center;
  padding: 8px;

  > div {
    max-width: 944px;
  }

  h2 {
    text-align: center;
    margin-bottom: 8px;
  }
`

export const ImagesContainer = styled.div`
  > div {
    padding: 16px 0;

    :not(:last-child) {
      border-bottom: 1px solid #abbed1;
    }

    img {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    display: flex;

    > div {
      padding: 0 16px;
      align-content: center;

      :not(:last-child) {
        border-right: 1px solid #abbed1;
        border-bottom: 0;
      }
    }
  }
`

export const StyledButton = styled(Button)<{ color?: string }>`
  ${({ color }) =>
    color &&
    `
    background-color: ${color};
    border-color: ${color};
  `}
`

export const Complement = styled.div`
  text-align: justify;
`
