import { BreakpointStart } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const FilterDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
  }

  @media (min-width: ${BreakpointStart.md}px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
  }
`

export const DataOrigin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`
