import { HierarchyCard, Text, useIsMobile } from '@yes.technology/react-toolkit'
import { ContentWrapper, StyledButton } from './AnimationSlider.styles'
import { useTranslation } from 'react-i18n-lite'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

type StarterPopupProps = {
  onClick: () => void
  des: string
  complement: string
  color?: string
}

const StarterPopup = ({
  onClick,
  des,
  complement,
  color
}: StarterPopupProps) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  return (
    <ContentWrapper>
      <HierarchyCard spacingClass='doubleXLarge'>
        <Text
          variant={isMobile ? 'content-emphasis-04' : 'content-emphasis-06'}
          as='h2'
        >
          {des}
        </Text>
        <Text variant={isMobile ? 'content-03' : 'bordertext-05'}>
          <SafeHtmlContent html={complement} />
        </Text>
        <StyledButton
          onClick={onClick}
          style={{ marginTop: '16px' }}
          heightClass={isMobile ? 'regular' : 'large'}
          color={color}
        >
          {t('animation-slider.start-animation')}
        </StyledButton>
      </HierarchyCard>
    </ContentWrapper>
  )
}

export default StarterPopup
