import { useState } from 'react'

import { ChartProps, CockpitProps } from './Cockpit.types'
import { GridContainer } from 'shared/style/grid'
import AppliedFilters from '../AppliedFilters/AppliedFilters'
import Sections from './components/Sections'
import ChartWrapper from './components/ChartWrapper'
import { useClassifiedCharts } from './hooks'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

export const getRows = (charts: ChartProps[]) => {
  const rows = []
  let currentRow: ChartProps[] = []
  let currentSum = 0

  charts.forEach((chart) => {
    const column = Number(chart?.column?.match(/\d+/)?.[0] ?? 12)

    if (currentSum + column > 12) {
      rows.push(currentRow)
      currentRow = []
      currentSum = 0
    }
    currentRow.push(chart)
    currentSum += column
  })

  if (currentRow.length > 0) {
    rows.push(currentRow)
  }

  return rows
}

const Cockpit = ({ props }: CockpitProps) => {
  const [section, setSection] = useState(0)
  const styleProps = useStyleMaps(props.styleMap)

  const handleNavigateSections = (index: number) => {
    setSection(index - 1)
  }

  const classificationUuid = props.uuid_classification || ''

  const { classifiedCharts, isLoading } = useClassifiedCharts({
    charts: props.data,
    classificationUuid
  })

  if (isLoading) return null

  const rows = getRows(classifiedCharts[section] as ChartProps[])

  return (
    <Sections
      onNavigate={handleNavigateSections}
      currentSection={section + 1}
      totalSections={classifiedCharts.length}
      style={styleProps}
    >
      <GridContainer>
        {rows.map((row, rowIndex) => (
          <div className={`row${rowIndex === 0 ? ' mt-3' : ''}`} key={rowIndex}>
            {row.map(
              (chart) =>
                chart && (
                  <ChartWrapper
                    key={chart.uuid}
                    dataset={chart.dataset}
                    des={chart.des}
                    template={chart.template}
                    uuid={chart.uuid}
                    column={chart.column}
                  />
                )
            )}
          </div>
        ))}
        <div className='row mb-3'>
          <div className='col-md-12'>
            <AppliedFilters />
          </div>
        </div>
      </GridContainer>
    </Sections>
  )
}

export default Cockpit
