import { Text } from '@yes.technology/react-toolkit'
import useBreakpointContent from 'site/Renderer/hooks/useBreakpointContent'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { NumericCardListProps } from './NumericCardList.types'
import {
  Card,
  CardContentContainer,
  Container,
  Content,
  ContentContainer
} from './styles'

const NumericCardList = ({ props }: NumericCardListProps) => {
  const { items = [], title, color = '#000000', styleMap } = props

  const styles = useStyleMaps(styleMap)

  const titleContent = useBreakpointContent(title)

  return (
    <Container $color={color} style={styles}>
      <Text style={{ textAlign: 'center' }} variant='heading-02' as='h2'>
        {titleContent}
      </Text>
      <ContentContainer role='list'>
        {items.map(({ uuid, des }, index) => {
          return (
            <Card key={uuid} role='listitem'>
              <CardContentContainer>
                <Text as='h3' variant='heading-02'>
                  {index + 1}
                </Text>
                <Content>
                  <SafeHtmlContent html={des} />
                </Content>
              </CardContentContainer>
            </Card>
          )
        })}
      </ContentContainer>
    </Container>
  )
}

export default NumericCardList
