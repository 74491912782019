import {
  Button,
  Classification,
  YesObject
} from '@yes.technology/react-toolkit'
import {
  Array,
  Literal,
  Number,
  Optional,
  Record,
  String,
  Union,
  Unknown
} from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type CardModel =
  | 'GenericObjectCard'
  | 'TaskObjectCard'
  | 'ContactObjectCard'
  | 'CollapsibleObjectCard'

export type ClassificationCardProps = {
  objects: YesObject[]
  classification: Classification
  cardProps?: CardProps
}

export type CardProps = {
  actions?: {
    des: string
    url: string
  }[]
}

export type DataCardsProps = {
  props: {
    uuid_objectclass?: string
    uuid_filteraction?: string
    uuid_classification?: string
    card_model: CardModel
    card_props?: CardProps
    show_pagination?: boolean
    preview_mode?: boolean
    message_no_data?: string
    pagination_limit?: number
    extra_card_props?: { [key: string]: string }
    styleMap?: StyleMap
    button_props?: React.ComponentProps<typeof Button>
  }
}

export const CardPropsTypeGuard = Record({
  actions: Optional(
    Array(
      Record({
        des: String,
        url: String
      })
    )
  )
})

export const DataCardsTypeGuard = Record({
  uuid_objectclass: Optional(String),
  uuid_filteraction: Optional(String),
  uuid_classification: Optional(String),
  card_model: Union(
    Literal('GenericObjectCard'),
    Literal('TaskObjectCard'),
    Literal('ContactObjectCard'),
    Literal('CollapsibleObjectCard')
  ),
  card_props: Optional(CardPropsTypeGuard),
  pagination_limit: Optional(Number),
  styleMap: Optional(Unknown),
  button_props: Optional(Unknown)
})
