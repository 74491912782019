import { YesObject } from '@yes.technology/react-toolkit'

import {
  Literal,
  Optional,
  Record,
  String,
  Union,
  Array,
  Unknown
} from 'runtypes'

import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type PositionType = 'left' | 'right'

export type ArticleButton = {
  label: string
  href: string
}

export type ArticleContainerProps = {
  props: {
    position: PositionType
    complement: string
    des?: string
    imageUrl?: string | YesObject[]
    imageAlt?: string
    buttons?: ArticleButton[]
    textColor?: string
    styleMap?: StyleMap
  }
}

const Position = Union(Literal('left'), Literal('right'))

export const ArticleContainerTypeGuard = Record({
  position: Position,
  complement: String,
  des: Optional(String),
  imageUrl: Optional(String),
  imageAlt: Optional(String),
  buttons: Optional(Array(Record({ label: String, href: String }))),
  styleMap: Optional(Unknown)
})
