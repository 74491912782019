import {
  FilterBody,
  Pagination,
  SortOption,
  YesObject,
  usePagination
} from '@yes.technology/react-toolkit'
import { useCallback, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import fetchYesObjects, {
  FetchYesObjectsResponse
} from '../api/fetchYesObjects'

import { fetchFilteraction } from 'filteraction/FilteractionResolver'
import fetchFilteractionFilterbody from 'filteraction/shared/api/fetchFilteractionFilterbody'
import { merge } from 'lodash'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

type UseFetchYesObjectsArgs = {
  initialPagination?: Pagination
  initialSortOptions?: SortOption[]
  filterBody?: Optional<FilterBody, 'pagination' | 'orderby'>
  showLoading?: boolean
  filteractionUuid?: string
  enabled?: boolean
  interpolationObject?: Record<string, unknown>
  shouldMergeFilters?: boolean
}

export const INITIAL_PAGINATION = { limit: 10, offset: 0 }

export const INITIAL_SORT_OPTIONS: SortOption[] = [
  {
    date_creation_row: 'desc'
  }
]

const emptyArr: YesObject[] = []

export default function useFetchYesObjects<T extends YesObject>({
  initialPagination = INITIAL_PAGINATION,
  initialSortOptions = INITIAL_SORT_OPTIONS,
  filterBody,
  filteractionUuid,
  showLoading,
  enabled = true,
  interpolationObject,
  shouldMergeFilters = false
}: UseFetchYesObjectsArgs) {
  const { pagination, setPage } = usePagination({
    initialPagination
  })

  const [sortOptions, setSortOptions] =
    useState<SortOption[]>(initialSortOptions)

  const { data, error, isLoading, isFetching, refetch, isFetched } = useQuery<
    FetchYesObjectsResponse,
    Error
  >({
    queryKey: [
      'YesObjects',
      pagination.limit,
      pagination.offset,
      { filterBody, showLoading, sortOptions },
      filteractionUuid
    ],
    queryFn: async () => {
      if (!filterBody && !filteractionUuid)
        return Promise.reject(
          new Error('Either filterBody or filteractionUuid must be provided.')
        )

      let callingFilterBody = {
        ...filterBody,
        pagination,
        orderby: sortOptions
      } as FilterBody

      if (filteractionUuid) {
        const filteraction = await fetchFilteraction(
          filteractionUuid,
          showLoading
        )

        const filteractionFilterBody = await fetchFilteractionFilterbody({
          filteraction,
          pagination,
          orderby: sortOptions,
          interpolationObject: interpolationObject
        })

        if (shouldMergeFilters) {
          callingFilterBody.query = {
            $and: [
              {
                ...merge(
                  filteractionFilterBody?.query ?? {},
                  filterBody?.query ?? {}
                )
              }
            ]
          }
        }

        callingFilterBody = {
          ...filteractionFilterBody,
          skip_metadata: filterBody?.skip_metadata,
          skip_relations: filterBody?.skip_relations,
          project_fields: filterBody?.project_fields
        }
      }

      return fetchYesObjects({ filterBody: callingFilterBody, showLoading })
    },
    enabled
  })

  const onSetSortOptions = useCallback(
    (sortOptions: SortOption[]) => {
      if (sortOptions?.length > 0) {
        const [sortOptionKey] = sortOptions
        const [sortOptionValue] = Object.values(sortOptionKey)

        setSortOptions(sortOptionValue ? sortOptions : initialSortOptions)
      }
    },
    [initialSortOptions]
  )

  return {
    yesObjects: (data?.objects || emptyArr) as T[],
    metadata: data?.metadata,
    isLoading,
    isFetching,
    isFetched,
    fetchYesObjects: refetch,
    pagination: data?.pagination || pagination,
    error,
    totalObjects: data?.pagination?.total || 0,
    setPage,
    sortOptions,
    setSortOptions: onSetSortOptions
  }
}
