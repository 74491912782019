import {
  Record as RTRecord,
  String,
  Dictionary,
  Unknown,
  Optional,
  Array,
  Union
} from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'
import { Component } from 'site/shared/types'

export type Tab = {
  uuid: string
  des: string
  complement: string
  components?: Component[]
}

export type TabsContentProps = {
  props: {
    items: Record<string, Tab>
    highlightColor?: string
    styleMap?: StyleMap
  }
}

export type TabsContentItemProps = {
  items: Record<string, Tab>
  currentKey: string
}

const Tab = RTRecord({
  uuid: String,
  des: String,
  complement: String
})

const TabComponents = RTRecord({
  uuid: String,
  components: Array(Unknown)
})

export const TabsContentTypeGuard = RTRecord({
  items: Dictionary(Union(Tab, TabComponents), String),
  highlightColor: Optional(String),
  styleMap: Optional(Unknown)
})
