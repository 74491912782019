import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #303032;
  gap: 24px;
  border-top: 2px solid #abbed1;
  padding: 60px 16px 20px 16px;
`

export const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  padding: 0;
  margin: 0;
`

export const NavigationListItem = styled.li`
  a {
    color: #303032;
    text-decoration: underline;
  }

  a:hover {
    color: #303032;
    text-decoration: none;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const LogoImage = styled.img`
  max-height: 60px;
`

export const Address = styled.address`
  text-align: center;
  margin: 0;
  border-top: 2px solid #f7f8fa;
  padding-top: 24px;
`

export const GoToTopContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const GoToTopButton = styled.button`
  padding: 0.5rem;
  background-color: #006bff;
  border: none;
  border-radius: 100%;
  color: white;
`

export const Copyright = styled.p`
  text-align: center;
  margin: 0;
`

export const SystemMessage = styled.p`
  max-width: 100%;
  text-align: center;
  margin: 0;

  @media (min-width: 768px) {
    max-width: 1200px;
  }
`
