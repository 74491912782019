import React from 'react'
import { TabsContentItemProps } from './TabsContent.types'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { TextContainer } from './TabsContent.styles'
import { Text, useIsMobile } from '@yes.technology/react-toolkit'
import LoadComponent from 'site/Renderer/LoadComponent'

function TabsContentItem({ currentKey, items }: TabsContentItemProps) {
  const isMobile = useIsMobile()

  const currentItem = items[currentKey]

  if (!currentItem) return null

  if (currentItem.components) {
    return <LoadComponent components={currentItem.components} />
  }

  return (
    <TextContainer>
      <Text variant={isMobile ? 'content-03' : 'content-04'}>
        <SafeHtmlContent html={currentItem?.complement} />
      </Text>
    </TextContainer>
  )
}

export default TabsContentItem
