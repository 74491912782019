import React from 'react'

export function ArrowDesktop() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='88'
      height='236'
      viewBox='0 0 88 236'
      fill='none'
    >
      <path
        d='M2.25462 233.331L85.5234 117.68L2.25463 2.0288'
        stroke='#ABBED1'
        strokeWidth='3.67025'
        strokeLinecap='round'
      />
    </svg>
  )
}

export function ArrowMobile() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='116'
      height='45'
      viewBox='0 0 116 45'
      fill='none'
    >
      <path
        d='M2.44461 2.42236L58.0001 42.4224L113.556 2.42236'
        stroke='#ABBED1'
        strokeWidth='3.67025'
        strokeLinecap='round'
      />
    </svg>
  )
}
