import {
  Button,
  GenericObjectCard as ToolkitGenObjCard,
  YesObject
} from '@yes.technology/react-toolkit'
import { Link } from 'react-router-dom'

import ImageDocument from 'component/ImageDocument/ImageDocument'
import { ActionButtonType, SitemodelContent } from './GenericObjectCard.types'
import React from 'react'
import { LoadComponent } from 'site/Renderer'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

export type GenericObjectCardProps = Pick<YesObject, 'des' | 'complement'> &
  Partial<Pick<YesObject, 'sitemodel_content' | 'Document'>> & {
    CustomAction?: React.ReactNode
    buttonProps?: React.ComponentProps<typeof Button>
  } & Partial<
    Pick<
      React.ComponentProps<typeof ToolkitGenObjCard>,
      'imageFormat' | 'imageHeight' | 'frameImage'
    >
  >

const GenericObjectCard = ({
  des,
  complement,
  sitemodel_content: sitemodelContent,
  Document: image,
  CustomAction = null,
  buttonProps,
  imageFormat,
  imageHeight,
  frameImage
}: GenericObjectCardProps) => {
  const JSONContent =
    typeof sitemodelContent === 'string' ? getJSONContent(sitemodelContent) : {}

  const actionButtons = getActionButtons(JSONContent)
  const previewComponents = getPreviewComponents(JSONContent)
  const defaultButton = getButtonByType(actionButtons, 'default')
  const workflowmodelButton = getButtonByType(actionButtons, 'workflowmodel')
  const helptexttButton = getButtonByType(actionButtons, 'helptext')

  const imageForwardProp = image && <ImageDocument documents={image} />
  const buttonForwardProp = defaultButton ? (
    <Link
      to={`/${defaultButton.action}`}
      style={{ width: '100%', textDecoration: 'none' }}
    >
      <Button
        style={{ fontSize: '12px' }}
        heightClass='semiSmall'
        variant='secondary'
      >
        {defaultButton.label}
      </Button>
    </Link>
  ) : undefined

  return (
    <ToolkitGenObjCard
      des={des}
      complement={<SafeHtmlContent html={complement} />}
      image={imageForwardProp}
      imageFormat={imageFormat}
      imageHeight={imageHeight}
      frameImage={frameImage}
      defaultButton={buttonForwardProp}
      fullHeight
    >
      {previewComponents && previewComponents.length > 0 && (
        <LoadComponent components={previewComponents} />
      )}
      {workflowmodelButton && (
        <Link
          to={`/${window.workflowmodelExecutorUuid}?workflowmodelUuid=${workflowmodelButton.action}`}
          style={{ width: '100%', textDecoration: 'none' }}
        >
          <Button
            {...buttonProps}
            style={{ fontSize: '10px', ...buttonProps?.style }}
            heightClass={buttonProps?.heightClass ?? 'small'}
            variant={buttonProps?.variant ?? 'primary'}
          >
            {workflowmodelButton.label}
          </Button>
        </Link>
      )}
      {helptexttButton && (
        <Link
          to={`/${helptexttButton.action}`}
          style={{ width: '100%', textDecoration: 'none' }}
        >
          <Button
            {...buttonProps}
            style={{ fontSize: '10px', ...buttonProps?.style }}
            heightClass={buttonProps?.heightClass ?? 'small'}
            variant={buttonProps?.variant ?? 'secondary'}
          >
            {helptexttButton.label}
          </Button>
        </Link>
      )}

      {CustomAction}
    </ToolkitGenObjCard>
  )
}

const getJSONContent = (jsonString: string) => {
  const json = JSON.parse(jsonString)
  return json
}

const getActionButtons = (content: SitemodelContent) => {
  return content.action_buttons
}

const getPreviewComponents = (content: SitemodelContent) => {
  return content.card_preview_components
}

const getButtonByType = (
  actionButtons: ActionButtonType[] | undefined,
  type: string
): ActionButtonType | null => {
  if (!actionButtons) return null
  const [first] = actionButtons?.filter((button) => button.type === type)
  return first || null
}

export default GenericObjectCard
