import { useIsMobile } from '@yes.technology/react-toolkit'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { statesWithOverlappingLabels } from 'site/Renderer/components/Cockpit/components/BrazilMap'
import { getContrastColor } from 'site/Renderer/components/Cockpit/utils/color'
import { ReactComponent as BrazilMapSvg } from '../../../Cockpit/assets/br.svg'
import MapFilter from '../MapFilter/MapFilter'
import {
  ActionButton,
  Divider,
  MapActionsContainer,
  MapContainer,
  MapWrapper
} from './styles'

type BrazilMapSelectableProps = {
  onChange: (state: string) => void
  selectedStates?: string[]
  selectedStateColor?: string
  defaultStateColor?: string
  strokeColor?: string
  selectedStateStrokeColor?: string
  mobileSelectedStateStrokeColor?: string
  mobileDefaultStrokeColor?: string
  displayLabels?: boolean
  disableMapInteractions?: boolean
  referencePeriodText: string
  displayZoomControls?: boolean
}

type MapStatesRef = { getAllStates: () => string[] }

const BrazilMapSelectable = forwardRef<
  MapStatesRef,
  React.PropsWithChildren<BrazilMapSelectableProps>
>(
  (
    {
      onChange,
      selectedStateColor = '#3E6F6B',
      defaultStateColor = '#F1F1F1',
      strokeColor = '#cfcfcf',
      selectedStateStrokeColor = '#ffffff',
      mobileDefaultStrokeColor = '#666666',
      mobileSelectedStateStrokeColor = '#ffffff',
      selectedStates = [],
      disableMapInteractions = false,
      displayLabels = false,
      referencePeriodText,
      displayZoomControls = true
    },
    ref
  ) => {
    const svgRef = useRef<SVGSVGElement>(null)

    const labelsRef = useRef<Record<string, SVGTextElement>>({})

    const textRefs = useRef<SVGTextElement[]>([])

    const linesRefs = useRef<SVGSVGElement[]>([])

    const initializationRef = useRef(false)

    const selectedStatesRef = useRef(selectedStates)

    const isMobile = useIsMobile()

    const [zoomState, setZoomState] = useState(0)

    useImperativeHandle(ref, () => ({
      getAllStates: () =>
        Array.from(svgRef?.current?.querySelectorAll('path') || []).map(
          (path) => path.id
        )
    }))

    useEffect(() => {
      const checkStateWithLabelOverlapping = (id: string) => {
        const statesWithOverlappingLabelsKeys = Object.keys(
          statesWithOverlappingLabels
        )

        return statesWithOverlappingLabelsKeys.includes(id)
      }

      const paths = svgRef?.current?.querySelectorAll('path') || []

      const statesG = svgRef?.current?.querySelector('#states_label')

      const circles = statesG?.querySelectorAll('circle') || []

      selectedStatesRef.current = selectedStates

      const onClickHandler = (event: MouseEvent) => {
        const path = event.target as SVGElement
        const id = path.id
        onChange(id)
      }

      if (!paths.length) return

      if (!initializationRef.current) {
        circles.forEach((circle) => {
          const id = circle.getAttribute('id') || ''
          let cx = circle.getAttribute('cx') || '0'
          let cy = circle.getAttribute('cy') || '0'

          const isStateWithLabelOverlapping = checkStateWithLabelOverlapping(id)

          const initialXStatePosition = cx // Posição inicial da linha de ligação no eixo X
          const initialYStatePosition = cy // Posição inicial da linha de ligação no eixo Y

          if (isStateWithLabelOverlapping) {
            const { offsetX, offsetY } = statesWithOverlappingLabels[id]

            cx = `${Number(cx) + offsetX}` // Posição final da linha de ligação no eixo X
            cy = `${Number(cy) + offsetY}` // Posição final da linha de ligação no eixo Y

            // Adiciona linha de ligação entre o estado e o label
            const lineLabelSvg = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'svg'
            )

            lineLabelSvg.setAttribute('width', '1000')
            lineLabelSvg.setAttribute('height', '1000')

            const line = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'line'
            )

            line.setAttribute('x1', initialXStatePosition)
            line.setAttribute('y1', initialYStatePosition)

            line.setAttribute('x2', cx)
            line.setAttribute('y2', cy)

            line.setAttribute('stroke', '#ccc')
            line.setAttribute('stroke-width', '1')

            lineLabelSvg.appendChild(line)

            linesRefs.current.push(lineLabelSvg)

            svgRef.current?.appendChild(lineLabelSvg)
          }

          const text = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'text'
          )

          text.setAttribute('x', cx)
          text.setAttribute('y', cy)
          text.setAttribute('id', id)
          text.setAttribute('font-size', '1.25rem')
          text.setAttribute('font-weight', '600')
          text.setAttribute('text-anchor', 'middle')
          text.setAttribute('fill', getContrastColor(defaultStateColor))
          text.setAttribute('stroke', 'none')
          text.style.pointerEvents = 'all'
          text.style.cursor = 'pointer'
          const tspanName = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'tspan'
          )

          const labelContent = id.replace('BR-', '')

          tspanName.textContent = labelContent
          tspanName.setAttribute('x', cx)
          tspanName.setAttribute('dy', '0')
          tspanName.setAttribute('id', id)

          const tspanLabel = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'tspan'
          )

          tspanLabel.setAttribute('x', cx)
          tspanLabel.setAttribute('dy', '1em')
          tspanLabel.setAttribute('id', id)

          text.appendChild(tspanName)
          text.appendChild(tspanLabel)

          textRefs.current.push(text)

          labelsRef.current = {
            ...labelsRef.current,
            [id]: text
          }

          svgRef.current?.appendChild(text)
        })

        initializationRef.current = true
      }

      paths.forEach((path) => {
        path.addEventListener('click', onClickHandler)
      })

      textRefs.current.forEach((text) => {
        text.addEventListener('click', onClickHandler)
      })

      paths.forEach((path) => {
        path.style.fill = defaultStateColor
        path.style.stroke = isMobile ? mobileDefaultStrokeColor : strokeColor
        const label = labelsRef.current[path.id]
        if (label) {
          label.style.fill = getContrastColor(defaultStateColor)
        }
      })

      const selectedStatesPaths = Array.from(paths).filter(({ id }) =>
        selectedStates.includes(id)
      )

      selectedStates.forEach((selectedState) => {
        const label = labelsRef.current[selectedState]
        if (label) {
          label.style.fill = checkStateWithLabelOverlapping(selectedState)
            ? '#000'
            : getContrastColor(selectedStateColor)
        }
      })

      if (selectedStatesPaths?.length) {
        selectedStatesPaths.forEach((selectedStatePath) => {
          selectedStatePath.style.fill = selectedStateColor
          selectedStatePath.style.stroke = isMobile
            ? mobileSelectedStateStrokeColor
            : selectedStateStrokeColor
        })
      }

      Object.values(labelsRef.current).forEach((label) => {
        label.style.display = isMobile || !displayLabels ? 'none' : 'block'
      })

      linesRefs.current.forEach((line) => {
        line.style.display = isMobile || !displayLabels ? 'none' : 'block'
      })

      return () => {
        paths.forEach((path) => {
          path.removeEventListener('click', onClickHandler)
        })

        textRefs.current.forEach((text) => {
          text.removeEventListener('click', onClickHandler)
        })
      }
    }, [
      selectedStates,
      selectedStateColor,
      defaultStateColor,
      displayLabels,
      isMobile,
      onChange
    ])

    useEffect(() => {
      if (svgRef?.current) {
        svgRef.current.style.transform = 'none'
      }
      setZoomState(0)
    }, [isMobile, displayZoomControls])

    const onZoom = (action: 'in' | 'out') => {
      const updatedZoomState = action === 'in' ? zoomState + 1 : zoomState - 1

      const transformMap = {
        0: 'none',
        1: 'scale(1.5) translate(15%, 15%)',
        2: 'scale(3.5) translate(30%, 30%)'
      }

      if (svgRef?.current) {
        svgRef.current.style.transform =
          transformMap[updatedZoomState as keyof typeof transformMap]
      }

      setZoomState(updatedZoomState)
    }

    return (
      <MapWrapper>
        {isMobile && <MapFilter referencePeriodText={referencePeriodText} />}
        <MapContainer
          disableMapInteractions={disableMapInteractions}
          zoomState={zoomState}
        >
          {!isMobile && <MapFilter referencePeriodText={referencePeriodText} />}
          <BrazilMapSvg width={'100%'} ref={svgRef} />
        </MapContainer>
        {isMobile && displayZoomControls && (
          <MapActionsContainer>
            <ActionButton
              disabled={zoomState === 2}
              onClick={() => onZoom('in')}
              aria-label='Zoom in'
            >
              +
            </ActionButton>
            <Divider />
            <ActionButton
              disabled={zoomState === 0}
              onClick={() => onZoom('out')}
              aria-label='Zoom out'
            >
              -
            </ActionButton>
          </MapActionsContainer>
        )}
      </MapWrapper>
    )
  }
)

BrazilMapSelectable.displayName = 'BrazilMapSelectable'

export default BrazilMapSelectable
