import { Array, Optional, Record, String, Unknown } from 'runtypes'
import { BreakpointContent } from 'site/Renderer/hooks/useBreakpointContent'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type NumericCardListItemProps = {
  uuid: string
  des: string
}

export type NumericCardListProps = {
  props: {
    items: NumericCardListItemProps[]
    title?: BreakpointContent | string
    color?: string
    styleMap?: StyleMap
  }
}

const NumericCardListItemGuard = Record({
  uuid: String,
  des: String
})

export const NumericCardListTypeGuard = Record({
  items: Array(NumericCardListItemGuard),
  title: Optional(Unknown),
  color: Optional(String),
  styleMap: Optional(Unknown)
})
