import { useIsMobile } from '@yes.technology/react-toolkit'
import { isEmpty } from 'lodash'

export type BreakpointContent = Partial<{
  [K in 'sm' | 'md']: string
}>

const useBreakpointContent = (
  content?: BreakpointContent | string,
  defaultContent = ''
) => {
  const isMobile = useIsMobile()

  if (typeof content === 'string') {
    return content
  }

  if (!content || isEmpty(content)) {
    return defaultContent
  }

  const mobileContent = content.sm || defaultContent

  const desktopContent = content.md || defaultContent

  return isMobile ? mobileContent : desktopContent
}

export default useBreakpointContent
