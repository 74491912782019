import styled from 'styled-components'

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 24px;
    align-items: flex-start;

    & > :nth-child(2n) {
      margin-top: 88px;
    }
  }

  @media (min-width: 1200px) {
    gap: 36px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 100%;

  @media (min-width: 992px) {
    max-width: min(19%, 330px);
  }
`

export const ItemImage = styled.img`
  max-width: 100%;
  height: 380px;
  object-fit: contain;
`

export const TextLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`
