import { LinkButton, useIsMobile } from '@yes.technology/react-toolkit'
import EmptyStates from 'component/EmptyStates/EmptyStates'
import GenericObjectCard from 'component/GenericObjectCard'
import Tabs from 'component/Tabs'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { FilteredItemsProps, TabType } from '../../FilterableMap.types'
import brazilMapShapes from './assets/BrazilMapShapeUrl'
import {
  CardWrapper,
  CardsContainer,
  Container,
  EmptyStatesContainer,
  TabsContainer
} from './styles'

type FilteredObjectsProps = {
  objects: FilteredItemsProps[]
  onTabChange: (tab: TabType) => void
  selectedStates: string[]
  target?: '_self' | '_blank'
  actionColor?: string
}

const FilteredObjects = forwardRef<HTMLDivElement, FilteredObjectsProps>(
  (
    {
      objects,
      selectedStates,
      target = '_blank',
      onTabChange,
      actionColor = '#006bff'
    },
    ref
  ) => {
    const { t } = useTranslation()

    const tabsItems = useMemo(
      () => [
        {
          uuid: 'state',
          des: t('filterable-map.state-domain')
        },
        {
          uuid: 'federal',
          des: t('filterable-map.federal-domain')
        }
      ],
      [t]
    )

    const isMobile = useIsMobile()

    const brasilMapShapeUrl = brazilMapShapes[isMobile ? 'mobile' : 'desktop']

    const tabsHeightClass = isMobile ? 'medium' : 'large'

    const onItemSelect = (itemKey: string) => {
      const tab = tabsItems.find(({ des }) => des === itemKey)!.uuid as TabType

      onTabChange(tab)
    }

    const emptyState = !selectedStates?.length
      ? 'no-state-selected'
      : 'no-results-found'

    return (
      <Container
        style={{
          backgroundImage: brasilMapShapeUrl
        }}
        ref={ref}
      >
        <TabsContainer>
          <Tabs
            heightClass={tabsHeightClass}
            onItemSelect={onItemSelect}
            items={tabsItems}
            highlightColor='#3E6F6B'
            resetStateOnItemsChange={false}
            role='tablist'
          />
        </TabsContainer>
        {!objects?.length ? (
          <EmptyStatesContainer $color={actionColor}>
            <EmptyStates state={emptyState} showComplement />
          </EmptyStatesContainer>
        ) : (
          <CardsContainer role='list'>
            {objects.map(({ complement, des, uuid, documento }) => (
              <CardWrapper key={uuid} role='listitem'>
                <GenericObjectCard
                  complement={complement}
                  des={des}
                  CustomAction={
                    documento?.[0]?.des ? (
                      <LinkButton
                        variant='secondary'
                        href={documento?.[0]?.des || ''}
                        target={target}
                        rel='noopener noreferrer'
                        style={{
                          color: actionColor,
                          borderColor: actionColor
                        }}
                      >
                        {t('filterable-map.document-label')}
                      </LinkButton>
                    ) : null
                  }
                />
              </CardWrapper>
            ))}
          </CardsContainer>
        )}
      </Container>
    )
  }
)

FilteredObjects.displayName = 'FilteredObjects'

export default FilteredObjects
