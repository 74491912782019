const FiltersIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={12}
    height={12}
    fill='currentColor'
  >
    <path
      fill='#303032'
      fillRule='evenodd'
      d='M2.632 2.282A20.13 20.13 0 0 1 6 2c1.147 0 2.273.097 3.368.282.37.063.632.385.632.753v.429c0 .326-.13.64-.36.87L7.103 6.87a.615.615 0 0 0-.18.435v1.201c0 .467-.263.893-.68 1.101l-.72.36a.308.308 0 0 1-.446-.275V7.305a.615.615 0 0 0-.18-.435L2.36 4.334a1.23 1.23 0 0 1-.36-.87v-.429c0-.368.262-.69.632-.753Z'
      clipRule='evenodd'
    />
  </svg>
)
export default FiltersIcon
