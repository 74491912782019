import { BreakpointStart } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const LegendsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  flex-direction: column;
  align-items: center;

  > :first-child {
    min-width: 212px;
  }

  @media (min-width: ${BreakpointStart.md}px) {
    flex-direction: row-reverse;
  }
`
