import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18n-lite'

import { merge } from 'lodash'

import {
  Col,
  FilterQuery,
  Row,
  SkeletonDots,
  YesObject
} from '@yes.technology/react-toolkit'

import EmptyStates from 'component/EmptyStates/EmptyStates'

import { StyledGrid } from 'shared/style/grid'

import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

import { useSiteState } from 'siteState/shared'

import { useFetchYesObjects } from 'yesObject/shared'

import NavigationSlider from '../CardCarousel/NavigationSlider/NavigationSlider'

import Event from './Event'

import useBoundFilterQueryElementUuid from 'siteState/shared/hooks/useBoundFilterQueryElementUuid'
import { EventCardContainerProps } from './EventCardContainer.types'

const EventCardContainer = ({ props }: EventCardContainerProps) => {
  const { language } = useTranslation()

  const [page, setPage] = useState(0)

  const {
    elementUuid,
    objects,
    uuid_objectclass: objectclassUuid,
    merge_filter_query: mergeFilterQuery,
    styleMap
  } = props

  const styleProps = useStyleMaps(styleMap)

  const boundFilterQueryElementUuid =
    useBoundFilterQueryElementUuid(elementUuid)

  const [filterQuery] = useSiteState<FilterQuery>(
    'filter-query',
    boundFilterQueryElementUuid
  )

  const finalFilterQuery = useMemo(() => {
    if (filterQuery && mergeFilterQuery) {
      return { ...merge(filterQuery, mergeFilterQuery) }
    }

    return filterQuery
  }, [filterQuery, mergeFilterQuery])

  const {
    yesObjects,
    isFetching: isLoadingObjects,
    fetchYesObjects
  } = useFetchYesObjects({
    filterBody: {
      query: finalFilterQuery,
      objectclass: objectclassUuid
    },
    enabled: !!finalFilterQuery
  })

  const resultObjects = useMemo(() => {
    if (yesObjects.length > 0) {
      return yesObjects
    } else if (objects?.length && objects?.length > 0) {
      return objects
    } else {
      return []
    }
  }, [yesObjects, objects])

  useEffect(() => {
    if (finalFilterQuery) fetchYesObjects()
  }, [finalFilterQuery, fetchYesObjects])

  const pages = useMemo(() => {
    const today = new Date()
    const currentMonth = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}`

    const mappedObjects: Record<string, YesObject[]> = {}

    resultObjects?.forEach((object) => {
      if (!object.start_date) {
        return
      }

      const startDate = new Date(object.start_date as string)
      const year = startDate.getFullYear()
      const month = startDate.getMonth()
      const dateKey = `${month}/${year}`

      if (Array.isArray(mappedObjects[dateKey])) {
        mappedObjects[dateKey].push(object)
      } else {
        mappedObjects[dateKey] = [object]
      }
    })

    const pages = Object.keys(mappedObjects)
      .map((key) => {
        const [month, year] = key.split('/')

        const date = new Date(Number(year), Number(month))
        const dateString = date.toLocaleDateString('en-US')

        const eventMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`

        return {
          date: dateString,
          objects: mappedObjects[key],
          eventMonth
        }
      })
      .sort((firstObject, secondObject) => {
        const firstDate = new Date(firstObject.date)
        const secondDate = new Date(secondObject.date)

        if (firstDate > secondDate) {
          return 1
        }

        if (firstDate < secondDate) {
          return -1
        }

        return 0
      })

    if (pages.length > 0) {
      let targetPageIndex = 0

      const currentMonthIndex = pages.findIndex(
        ({ eventMonth }) => eventMonth === currentMonth
      )

      const futureMonthIndex = pages.findIndex(
        ({ eventMonth }) => eventMonth > currentMonth
      )

      const pastMonthIndex = pages.findLastIndex(
        ({ eventMonth }) => eventMonth < currentMonth
      )

      if (!targetPageIndex) {
        if (currentMonthIndex !== -1) {
          targetPageIndex = currentMonthIndex
        } else if (futureMonthIndex !== -1) {
          targetPageIndex = futureMonthIndex
        } else if (pastMonthIndex !== -1) {
          targetPageIndex = pastMonthIndex
        }
      }

      setPage(targetPageIndex)
    }

    return pages
  }, [resultObjects])

  const disabledArrow = useMemo(() => {
    if (page === 0) return 'prev'
    if (page === pages.length - 1) return 'next'
    return undefined
  }, [page, pages.length])

  const handlePrevClick = () => {
    setPage((prevPage) => (prevPage === 0 ? prevPage : prevPage - 1))
  }

  const handleNextClick = () => {
    setPage((prevPage) =>
      prevPage === pages.length - 1 ? prevPage : prevPage + 1
    )
  }

  const title = useMemo(() => {
    const currentPage = pages[page]

    if (!currentPage) {
      return ''
    }

    const date = new Date(currentPage.date)
    const month = date.toLocaleString(language, { month: 'long' })
    const year = date.getFullYear()

    return `${month.charAt(0).toUpperCase()}${month.slice(1)}/${year}`
  }, [page, pages, language])

  if (isLoadingObjects) {
    return (
      <StyledGrid style={styleProps}>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <SkeletonDots />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  if (resultObjects.length === 0) {
    return (
      <EmptyStates
        state={!!finalFilterQuery ? 'no-results-found' : 'no-data-to-view'}
        style={styleProps}
      />
    )
  }

  return (
    <>
      <NavigationSlider
        title={title}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        disabled={pages.length <= 1}
        disabledArrow={disabledArrow}
        currentIndex={page}
      />

      <StyledGrid style={styleProps}>
        <Row style={{ justifyContent: 'center' }}>
          {pages[page]?.objects.map((object) => {
            return <Event key={object.uuid} {...{ object }} />
          })}
        </Row>
      </StyledGrid>
    </>
  )
}

export default EventCardContainer
