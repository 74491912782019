import styled from 'styled-components'

import { Button } from '@yes.technology/react-toolkit'

import { getBaseBackgroundImageStyle } from './HeroBannerSecondary.utils'

export const Container = styled.section<{
  leftBackgroundImageUrl?: string
  rightBackgroundImageUrl?: string
  backgroundColor?: string
}>`
  background: ${({ backgroundColor }) => backgroundColor || '#006bff'};
  padding: 0.5rem 1rem;

  @media (min-width: 768px) {
    padding: 1.25rem 175px;
    position: relative;
  }

  @media (min-width: 1920px) {
    padding: 1.25rem 210px;
  }
`

export const LeftImage = styled.div<{ leftBackgroundImageUrl?: string }>`
  left: 0;
  background-position-x: right;

  ${({ leftBackgroundImageUrl }) =>
    getBaseBackgroundImageStyle(leftBackgroundImageUrl)};
`

export const RightImage = styled.div<{ rightBackgroundImageUrl?: string }>`
  right: 0;
  background-position-x: left;

  ${({ rightBackgroundImageUrl }) =>
    getBaseBackgroundImageStyle(rightBackgroundImageUrl)};
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 768px) {
    max-width: 1220px;
    flex-direction: column;
    margin: 0 auto;
    gap: 1.5rem;
    padding: 1rem 0;
  }
`

export const Title = styled.h2`
  color: ${({ theme, color }) => color || theme.colors.CESwhite};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-bottom: 0;

  @media (min-width: 768px) {
    text-align: center;
    font-size: 3.5rem;
    line-height: 3rem;
  }
`

export const Subtitle = styled.h3`
  color: ${({ theme, color }) => color || theme.colors.CESwhite};
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const StyledButton = styled(Button)`
  width: 2rem;
  height: 2rem;
`
