import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToHashElement = () => {
  const location = useLocation()
  let hash = location.hash
  let element: HTMLElement | null = null

  if (hash) {
    hash = hash.slice(1)
    element = document.getElementById(hash)
  }

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'nearest'
      })
    }
  }, [element])

  return null
}

export default ScrollToHashElement
