import { ComponentProps, useCallback, useEffect, useMemo } from 'react'
import {
  AutocompleteMultiselection,
  FilterQuery,
  YesObject
} from '@yes.technology/react-toolkit'

import { FieldValues } from '../../types'
import useSuggestionsOnChange from '../../hooks/useSuggestionsOnChange'

type AutocompleteMultiselectionProps = ComponentProps<
  typeof AutocompleteMultiselection
>

type Option = ComponentProps<typeof AutocompleteMultiselection>['options'][0]

type GetOptionsParams = {
  selectedOptions: Option[] | undefined
  suggestions: YesObject[]
}

const getOptions = ({ selectedOptions, suggestions }: GetOptionsParams) => {
  const mappedSuggestions = suggestions.map(({ uuid, des }) => ({
    id: uuid,
    value: des
  }))

  if (!selectedOptions) {
    return []
  }

  const uniqueSelectedOptions = selectedOptions.filter(
    (selectedOption) =>
      !suggestions.find((suggestion) => suggestion.uuid === selectedOption.id)
  )

  const options: Option[] = [...uniqueSelectedOptions, ...mappedSuggestions]

  return options
}

type AutocompleteMultiselectionFieldProps = {
  inputRef?: React.Ref<HTMLInputElement>
  objectclassUuid: string
  onChangeValues: (values: FieldValues['']['value']) => void
  interdependentFilteringQuery?: FilterQuery
} & Partial<AutocompleteMultiselectionProps>

const AutocompleteMultiselectionField = ({
  inputRef,
  objectclassUuid,
  onChangeValues,
  selectedOptions = [],
  interdependentFilteringQuery,
  ...props
}: AutocompleteMultiselectionFieldProps) => {
  const projectFields = useMemo(() => ({ des: 1 }), [])
  const { value, onChange, isLoading, suggestions, resetValue } =
    useSuggestionsOnChange({
      objectclassUuid,
      interdependentFilteringQuery,
      skipMetadata: true,
      skipRelations: true,
      projectFields
    })

  useEffect(() => {
    if (selectedOptions.length === 0) {
      resetValue()
    }
  }, [selectedOptions, resetValue])

  const onSelect = useCallback(
    (targetSelection: Option) => {
      const isOptionAlreadySelected = selectedOptions.find(
        ({ id }) => id === targetSelection.id
      )

      const optionsAfterSelection = [...selectedOptions, targetSelection]
      const optionsAfterDeselection = selectedOptions.filter(
        ({ id }) => id !== targetSelection.id
      )

      const newSelectedOptions = isOptionAlreadySelected
        ? optionsAfterDeselection
        : optionsAfterSelection
      onChangeValues(
        newSelectedOptions.map((selectedOption) => ({
          uuid: selectedOption.id,
          des: selectedOption.value
        }))
      )
    },
    [onChangeValues, selectedOptions]
  )

  const options = getOptions({ selectedOptions, suggestions })

  return (
    <AutocompleteMultiselection
      {...props}
      ref={inputRef}
      statusClass={isLoading ? 'processing' : 'default'}
      {...{ options, value, onChange, selectedOptions, onSelect }}
    />
  )
}

export default AutocompleteMultiselectionField
