import { Text } from '@yes.technology/react-toolkit'
import { ItemButton, ItemContainer, VerticalSeparator } from './TabItem.styles'
import { TabItemProps } from './Tabs.types'
import {
  TextClassesEnum,
  TextClassesEnumFocused,
  textColorMap
} from './Tabs.enum'
import { useRef } from 'react'

export default function TabItem({
  tab,
  tabIndex,
  selectedIndex,
  highlightColor,
  heightClass,
  textColor,
  onItemSelected
}: TabItemProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const isSelected = tabIndex === selectedIndex
  const textVariant = isSelected
    ? TextClassesEnumFocused[heightClass]
    : TextClassesEnum[heightClass]
  const textColorClass =
    isSelected && highlightColor ? highlightColor : textColorMap[textColor]

  return (
    <ItemContainer heightClass={heightClass} ref={containerRef}>
      {containerRef.current?.offsetLeft !== 0 && (
        <VerticalSeparator heightClass={heightClass} />
      )}
      <ItemButton
        role='tab'
        type='button'
        aria-selected={isSelected}
        onClick={() => onItemSelected(tabIndex)}
      >
        <Text variant={textVariant} style={{ color: textColorClass }}>
          {tab.des}
        </Text>
      </ItemButton>
    </ItemContainer>
  )
}
