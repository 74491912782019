import { useQuery } from '@tanstack/react-query'
import { FilterQuery } from '@yes.technology/react-toolkit'
import safDataResponseMock from './mocks/saf-data-response-mock.json'
import { SaF } from '@yes.technology/react-toolkit'

type UseSaFQueryProps = {
  filterQuery?: FilterQuery
}

type DataResponse = {
  success: boolean
} & Pick<React.ComponentProps<typeof SaF>, 'columns' | 'data'>

const fakeWait = () => new Promise((resolve) => setTimeout(resolve, 2000))

// TODO update request with real endpoint when ready
export default function useSaFQuery({ filterQuery }: UseSaFQueryProps) {
  return useQuery<DataResponse>({
    queryKey: ['use-saf-query', filterQuery],
    queryFn: async () => {
      await fakeWait()

      return safDataResponseMock as DataResponse
    },
    enabled: !!filterQuery
  })
}
