import React from 'react'
import {
  Pagination as PaginationType,
  defaultTheme
} from '@yes.technology/react-toolkit'

import { HierarchyLevel } from './types'
import ColumnsNavigation from './components/ColumnsNavigation'
import Pagination from '../Pagination'
import SectionDivider from '../SectionDivider'
import { useTranslation } from 'react-i18n-lite'
import { AreaLineCenter } from './DataTable.styles'
import LayoutAndReport from './components/LayoutAndReport/LayoutAndReport'

type DataTableAreaLineProps = {
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  pagination?: PaginationType
  setCurrentPage?: (page: number) => void
  hierarchyLevel: HierarchyLevel
  title: string
  isShowTitle?: boolean
  isCollapsibleCard?: boolean
  titleOnly?: boolean
  children?: React.ReactNode
  embedded?: boolean
  disabled?: boolean
  hideMainCollapsibleContainer?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const primaryColor = (defaultTheme as any).colors.primary || '#006bff'

export default function DataTableAreaLine({
  navigationSliderColor,
  navigationSliderBackgroundColor = '#004099',
  pagination,
  hierarchyLevel,
  title,
  isShowTitle = true,
  isCollapsibleCard = false,
  titleOnly,
  children,
  setCurrentPage = () => '',
  embedded,
  hideMainCollapsibleContainer = false,
  ...htmlProps
}: DataTableAreaLineProps) {
  const { t } = useTranslation()

  const completeTitle = isShowTitle
    ? title
      ? `${title} | ${t('area-title.data-tables')}`
      : t('area-title.data-tables')
    : ''

  const centerHeaderElement = titleOnly ? undefined : (
    <AreaLineCenter>
      <ColumnsNavigation
        hierarchyLevel={hierarchyLevel}
        navigationSliderBackgroundColor={
          isCollapsibleCard ? primaryColor : navigationSliderBackgroundColor
        }
        navigationSliderColor={
          isCollapsibleCard ? primaryColor : navigationSliderColor
        }
      />
      <Pagination
        sectionText='Página'
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        hierarchyLevel={hierarchyLevel}
        className='d-flex justify-content-lg-end flex-column flex-lg-row'
        navigationSliderBackgroundColor={
          isCollapsibleCard ? primaryColor : navigationSliderBackgroundColor
        }
        navigationSliderColor={
          isCollapsibleCard ? primaryColor : navigationSliderColor
        }
      />
    </AreaLineCenter>
  )

  // todo: add a prop to hide the layout and report
  // const rightHeaderElement = titleOnly ? undefined : <LayoutAndReport />
  const rightHeaderElement = undefined

  return (
    <SectionDivider
      title={completeTitle}
      isCollapsibleCard={isCollapsibleCard}
      centerHeaderElement={centerHeaderElement}
      rightHeaderElement={rightHeaderElement}
      backgroundColor={navigationSliderBackgroundColor}
      color={navigationSliderColor}
      embedded={embedded}
      isVisible={!hideMainCollapsibleContainer}
      {...htmlProps}
    >
      {children}
    </SectionDivider>
  )
}
