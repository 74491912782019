import { useState } from 'react'

import { useTranslation } from 'react-i18n-lite'

import { SortOption } from '@yes.technology/react-toolkit'

import { SortDirection } from 'shared/utils/array/sort'
import { concatenateDisplayText } from 'shared/utils/object/concatenateFields'

import { INITIAL_SORT_OPTIONS } from 'yesObject/shared/hooks/useFetchYesObjects'

import { DataTableSkeletonDots, TableHeaderColumn } from '../DataTable.styles'

import useTableContext from '../hooks/useTableContext'

import {
  ArrowsUpAndDown,
  AscendingSortArrow,
  DescendingSortArrow
} from '../SVG'

type TableHeaderProps = {
  isLoading: boolean
}

const nextClassificationMap = {
  '': 'asc',
  asc: 'desc',
  desc: ''
}

const nextClassificationMapReverse = {
  desc: 'asc',
  asc: '',
  '': 'desc'
}

function TableHeader({ isLoading }: TableHeaderProps) {
  const { t } = useTranslation()

  const {
    mode,
    fields,
    actionColumns,
    actionColumnsPosition,
    columnsNames,
    columnsLayouts,
    sortOptions,
    onSort,
    informational,
    actionButton,
    embedded,
    hideRowActionButtons,
    concatFields
  } = useTableContext()

  const [initialSortOptions] = useState(sortOptions)

  const contentCells = columnsNames.map((columnName) => {
    const sortOptionsObject =
      sortOptions?.find((sortOption) => !!sortOption[columnName]) || {}

    const currentClassification = sortOptionsObject?.[columnName] || ''

    const updateSortDirection = () => {
      const isInitialSortOption = INITIAL_SORT_OPTIONS.find(
        (initialSortOption) => Object.keys(initialSortOption)[0] === columnName
      )

      const nextClassification =
        isInitialSortOption || initialSortOptions[0]?.[columnName] === 'desc'
          ? nextClassificationMapReverse[currentClassification]
          : nextClassificationMap[currentClassification]

      const newSortOptions: SortOption[] = [
        { [columnName]: nextClassification as SortDirection }
      ]

      onSort(newSortOptions)
    }

    const columnLayout = columnsLayouts.find(
      (layout) => layout.name === columnName
    )
    const columnWidth = columnLayout?.width || ''

    const thProps = {
      style: { width: columnWidth }
    }

    if (isLoading) {
      return (
        <TableHeaderColumn key={columnName} {...thProps}>
          <DataTableSkeletonDots />
        </TableHeaderColumn>
      )
    }

    const columnDisplayText = concatenateDisplayText({
      concatFields,
      fields,
      columnName
    })

    return (
      <TableHeaderColumn key={columnName} {...thProps}>
        {mode === 'view' && fields?.[columnName]?.type !== 'RELATION' && (
          <button
            role='button'
            className={`${
              sortOptionsObject ? 'sort-icon-active' : 'sort-icon'
            }`}
            style={{ backgroundColor: 'transparent', border: 0 }}
            onClick={updateSortDirection}
            data-testid={`sort-${Object.keys(sortOptionsObject)[0]}-${Object.values(sortOptionsObject)[0]}`}
          >
            {currentClassification === 'asc' && <AscendingSortArrow />}
            {currentClassification === 'desc' && <DescendingSortArrow />}
            {currentClassification === '' && <ArrowsUpAndDown />}
          </button>
        )}
        {columnDisplayText}
      </TableHeaderColumn>
    )
  })

  const showColumn =
    !embedded || actionButton?.type === 'document' || !informational

  let actionCells = actionColumns.map(
    (column) =>
      showColumn && (
        <TableHeaderColumn
          style={{ width: '5%' }}
          className='text-center'
          key={column.title}
        >
          {' '}
          {isLoading ? (
            <DataTableSkeletonDots />
          ) : (
            t(`data-table.${column.title}`)
          )}{' '}
        </TableHeaderColumn>
      )
  )

  const selectCell = !informational && mode === 'view' && (
    <TableHeaderColumn style={{ width: '5%' }} key='select-cell'>
      {isLoading ? <DataTableSkeletonDots /> : t(`data-tables.select`)}
    </TableHeaderColumn>
  )

  if (!fields) {
    return null
  }

  actionCells = hideRowActionButtons ? [] : actionCells

  return (
    <thead>
      <tr>
        {actionColumnsPosition === 'first'
          ? [selectCell, ...actionCells, ...contentCells]
          : [selectCell, ...contentCells, ...actionCells]}
      </tr>
    </thead>
  )
}

export default TableHeader
