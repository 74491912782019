import React from 'react'
import { ActionStepsProps } from './types'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { StyledGrid } from 'shared/style/grid'
import { Item, ItemImage, ItemsContainer, TextLine } from './ActionStep.styles'
import { Text, useIsMobile, YesObject } from '@yes.technology/react-toolkit'
import usePreloadingImages from 'shared/hooks/usePreloadingImages/usePreloadingImages'
import { ArrowDesktop, ArrowMobile } from './Arrow'
import { SIZE_LG, SIZE_XL, useBreakpoint } from 'shared/hooks/useBreakpoint'

function ResponsiveArrow() {
  const breakpoint = useBreakpoint()

  if ([SIZE_LG, SIZE_XL].includes(breakpoint)) return <ArrowDesktop />

  return <ArrowMobile />
}

function ActionSteps({ props }: ActionStepsProps) {
  const { items, styleMap } = props

  const isMobile = useIsMobile()
  const styleProps = useStyleMaps(styleMap)

  const documentUUIDs = items
    ?.map((item) => (item?.documento?.[0] as YesObject)?.uuid)
    .filter(Boolean)

  const images = usePreloadingImages({ documentUUIDs })

  return (
    <StyledGrid style={styleProps}>
      <ItemsContainer>
        {items.map((item, index, items) => {
          const documentUuid = (item?.documento?.[0] as YesObject)?.uuid
          const image = images?.find((image) => image?.[documentUuid])

          return (
            <React.Fragment key={item.uuid}>
              <Item>
                <ItemImage src={image?.[documentUuid]} />

                <TextLine>
                  <Text
                    variant={
                      isMobile ? 'heading-emphasis-05' : 'heading-emphasis-01'
                    }
                  >
                    {index + 1}
                  </Text>

                  <Text variant='content-06'>{item.complement}</Text>
                </TextLine>
              </Item>
              {index !== items.length - 1 && <ResponsiveArrow />}
            </React.Fragment>
          )
        })}
      </ItemsContainer>
    </StyledGrid>
  )
}

export default ActionSteps
