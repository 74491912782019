import { LinkButton } from '@yes.technology/react-toolkit'
import { Optional, Record, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type ActionButtonProps = {
  props: React.ComponentProps<typeof LinkButton> & { styleMap?: StyleMap }
}

export const ActionButtonTypeGuard = Record({
  styleMap: Optional(Unknown)
})
