import {
  HierarchyCard,
  SkeletonDots,
  Text
} from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const OrderTableContainer = styled(HierarchyCard)`
  margin-bottom: 8px;
  margin-top: 8px;
`

export const OrderTableSectionHeader = styled(Text).attrs({
  variant: 'content-emphasis-04'
})`
  background-color: #0056cc;
  border-radius: 6px 6px 0 0;
  height: 56px;
  width: calc(100% + 16px);
  position: relative;
  left: -8px;
  top: -8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`

export const WhiteSkeleton = styled(SkeletonDots)`
  & > div {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const OrderTableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  & > *:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.colorSupportLightGrey};
  }
`

export const ItemContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
`

export const OrderTableValue = styled(Text).attrs({ variant: 'content-04' })`
  display: block;
  padding: 0px 8px;
`
