import {
  CollapsibleCard,
  HierarchyCard,
  Text
} from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useState } from 'react'
import { AnimationSliderType } from './AnimationSlider.types'
import {
  CardContainer,
  CollapsibleSection,
  Complement,
  Container,
  ImagesContainer,
  ImageWrapper,
  SlideNumber,
  SlideTitle,
  Subtitle,
  SubtitleWrapper
} from './AnimationSlider.styles'
import NavigationSlider from './NavigationSlider'
import StarterPopup from './StarterPopup'
import { useTranslation } from 'react-i18n-lite'
import useWindowDimensions from 'shared/hooks/useWindowDimensions'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

const AnimationSlider = ({
  slides,
  subtitles,
  starterPopup,
  color,
  textColor
}: AnimationSliderType) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isAnimationStarted, setIsAnimationStarted] = useState(true)

  const { t } = useTranslation()
  const { windowWidth } = useWindowDimensions()

  const isMobile = windowWidth < 992

  //preload images
  useEffect(() => {
    for (const slide of slides) {
      const imageElement = new Image()
      imageElement.src = isMobile
        ? slide.image.mobile.url
        : slide.image.desktop.url
    }
  }, [slides, isMobile])

  const navigateToNext = useCallback(() => {
    setCurrentSlide((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    )
  }, [slides.length])

  const navigateToPrev = () => {
    setCurrentSlide((prevIndex) => prevIndex - 1)
  }

  useEffect(() => {
    if (!isAnimationStarted) return

    const interval = setInterval(() => {
      navigateToNext()
    }, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [isAnimationStarted, navigateToNext])

  const renderTitle = () => (
    <SlideTitle>
      <SlideNumber color={color} textColor={textColor}>
        <Text
          variant={isMobile ? 'content-emphasis-04' : 'content-emphasis-06'}
        >
          {currentSlide + 1}
        </Text>
      </SlideNumber>
      <Text variant={isMobile ? 'content-emphasis-04' : 'content-emphasis-06'}>
        {slides[currentSlide].des}
      </Text>
    </SlideTitle>
  )

  return (
    <>
      <Container>
        {!isAnimationStarted && (
          <StarterPopup
            onClick={() => {
              setIsAnimationStarted(true)
            }}
            color={color}
            {...starterPopup}
          />
        )}
        {!isMobile && (
          <ImageWrapper>
            <img
              src={slides[currentSlide].image.desktop.url}
              alt={slides[currentSlide].image.desktop.alt}
            />
          </ImageWrapper>
        )}
        {isAnimationStarted && (
          <CardContainer>
            <HierarchyCard spacingClass='doubleXLarge'>
              <NavigationSlider
                currentIndex={currentSlide + 1}
                length={slides.length}
                onNextClick={navigateToNext}
                onPrevClick={navigateToPrev}
                color={color}
              />
              <Complement>
                {isMobile ? (
                  <CollapsibleSection
                    leftHeaderElement={renderTitle()}
                    heightClass='small'
                  >
                    <Text variant='bordertext-05'>
                      <SafeHtmlContent html={slides[currentSlide].complement} />
                    </Text>
                  </CollapsibleSection>
                ) : (
                  <>
                    {renderTitle()}
                    <Text variant='bordertext-05'>
                      <SafeHtmlContent html={slides[currentSlide].complement} />
                    </Text>
                  </>
                )}
              </Complement>
            </HierarchyCard>
          </CardContainer>
        )}
        {isMobile && (
          <ImageWrapper>
            <img
              src={slides[currentSlide].image.mobile.url}
              alt={slides[currentSlide].image.mobile.alt}
            />
          </ImageWrapper>
        )}
      </Container>
      <SubtitleWrapper>
        {isMobile ? (
          <CollapsibleCard
            leftHeaderElement={
              <Text variant='content-emphasis-04'>
                {t('animation-slider.subtitle')}
              </Text>
            }
            heightClass='small'
          >
            <ImagesContainer>
              {subtitles.map((subtitle, index) => (
                <div key={index}>
                  <img src={subtitle} />
                </div>
              ))}
            </ImagesContainer>
          </CollapsibleCard>
        ) : (
          <HierarchyCard>
            <Subtitle>
              <Text variant='content-emphasis-05'>
                {t('animation-slider.subtitle')}
              </Text>
              <ImagesContainer>
                {subtitles.map((subtitle, index) => (
                  <div key={index}>
                    <img src={subtitle} />
                  </div>
                ))}
              </ImagesContainer>
            </Subtitle>
          </HierarchyCard>
        )}
      </SubtitleWrapper>
    </>
  )
}

export default AnimationSlider
