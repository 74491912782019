import { useMemo, useState } from 'react'
import { isNotIncludedIn } from 'shared/utils'
import { ColumnLayout } from '../types'

type UseColumnsParams = {
  columns: string[]
  columnsLayouts: ColumnLayout[]
  amountOfActionColumns?: number
}

// As colunas de ação e seleção também devem ser consideradas como colunas fixas e a navegação de colunas deve considerar isso.
export default function useColumns({
  columns,
  columnsLayouts = [],
  amountOfActionColumns = 0
}: UseColumnsParams) {
  const { pinnedColumns, unpinnedVisibleColumnsCount } = useMemo(
    () => ({
      pinnedColumns: columnsLayouts
        .filter((column) => column.freeze)
        .map((column) => column.name),
      unpinnedVisibleColumnsCount: columnsLayouts.filter(
        (column) => !column.freeze
      ).length
    }),
    [columnsLayouts]
  )

  const unpinnedColumns = useMemo(() => {
    // TODO: E se usássemos as colunas completas ao invés dos nomes?
    const layoutColumnsNames = columnsLayouts.map((column) => column.name)

    const unpinnedLayoutColumns = layoutColumnsNames.filter(
      isNotIncludedIn(pinnedColumns)
    )
    const columnsNotInLayout = columns.filter(
      isNotIncludedIn(layoutColumnsNames)
    )

    return [...unpinnedLayoutColumns, ...columnsNotInLayout]
  }, [columns, columnsLayouts, pinnedColumns])

  const [currentColumnIndex, setCurrentColumnIndex] = useState(0)

  const totalColumns =
    (pinnedColumns?.length || 0) +
    unpinnedColumns.length +
    amountOfActionColumns

  const amountOfFixedColumns =
    unpinnedVisibleColumnsCount + pinnedColumns.length + amountOfActionColumns

  const displayedColumns = useMemo(() => {
    const startIndex = Math.max(0, currentColumnIndex)

    const endIndex = startIndex + unpinnedVisibleColumnsCount

    const currentUnpinnedColumns = unpinnedColumns.slice(startIndex, endIndex)

    return [...pinnedColumns, ...currentUnpinnedColumns]
  }, [
    unpinnedVisibleColumnsCount,
    currentColumnIndex,
    unpinnedColumns,
    pinnedColumns
  ])

  const onChangeColumnsPage = (value: number) => {
    const currentColumn = value - amountOfFixedColumns

    setCurrentColumnIndex(currentColumn)
  }

  return {
    displayedColumns,
    onChangeColumnsPage,
    currentColumnIndex,
    totalColumns,
    amountOfFixedColumns
  }
}
