import { useCallback, useEffect, useMemo, useRef } from 'react'

import { APIProvider, ControlPosition, Map } from '@vis.gl/react-google-maps'

import { merge } from 'lodash'

import { FilterQuery, YesLoading } from '@yes.technology/react-toolkit'

import { useGeolocation } from 'shared/hooks'

import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

import { useSiteState } from 'siteState/shared'

import { useFetchYesObjects } from 'yesObject/shared'

import { CustomMapControl } from './CustomMapControl'
import { usePlace } from './hooks'
import { MapHandler } from './MapHandler'
import { MapMarker } from './MapMarker'
import { NoDataFound } from './NoDataFound'

import { MapContainerProps } from './MapContainer.types'

const defaultFilterQuery = {
  $and: [
    {
      'situation.des': {
        $eq: '40'
      }
    }
  ]
}

const MapContainer = ({ props }: MapContainerProps) => {
  const {
    uuid_objectclass: objectclassUuid,
    uuid_filteraction: filteractionUuid,
    message_no_data_found: messageNoDataFound,
    styleMap,
    hide_custom_map_control
  } = props

  const styleProps = useStyleMaps(styleMap)

  const [filterFieldsQuery] = useSiteState<FilterQuery>('filter-query')

  const {
    setSelectedPlace,
    cityUuid,
    selectedPlace,
    isLoading: isLoadingPlace
  } = usePlace()

  const filterQuery = useMemo(() => {
    const internalFilterQuery = {
      $and: [
        {
          'cidade.uuid': {
            $in: [cityUuid]
          }
        }
      ]
    }

    return {
      ...merge(filterFieldsQuery ?? internalFilterQuery, defaultFilterQuery)
    }
  }, [filterFieldsQuery, cityUuid])

  const {
    yesObjects,
    isLoading: isLoadingObjects,
    fetchYesObjects
  } = useFetchYesObjects({
    filterBody: {
      query: filterQuery,
      objectclass: objectclassUuid,
      project_fields: {
        address: 1,
        des: 1,
        gruposalvos: 1,
        cidade: 1,
        estado: 1,
        workingschedule: 1,
        documento: 1,
        uuid: 1,
        produtos: 1
      }
    },
    initialPagination: {
      limit: 99999,
      offset: 0
    },
    filteractionUuid,
    enabled: false
  })

  useEffect(() => {
    if (cityUuid || filterFieldsQuery) {
      fetchYesObjects()
    }
  }, [cityUuid, filterFieldsQuery, fetchYesObjects])

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (selectedPlace && scrollRef.current) {
      const offsetTop =
        scrollRef.current.getBoundingClientRect().top + window.scrollY
      const marginTop = 20
      window.scrollTo({ top: offsetTop - marginTop, behavior: 'smooth' })
    }
  }, [selectedPlace])

  useEffect(handleScroll, [handleScroll])

  const initialCamera = {
    center: { lat: -13.9763943, lng: -54.8382792 },
    zoom: 10
  }

  const defaultCenter: google.maps.LatLngLiteral = initialCamera.center

  const { currentPosition } = useGeolocation()

  const handleSelectPlace = (place: google.maps.places.PlaceResult | null) =>
    setSelectedPlace(place)

  const isNoDataFound =
    messageNoDataFound &&
    !isLoadingObjects &&
    cityUuid &&
    yesObjects.length === 0

  const isLoading = isLoadingObjects || isLoadingPlace

  return (
    <APIProvider apiKey={window.googleCloudApikey} libraries={['marker']}>
      <div className='position-relative' ref={scrollRef} style={styleProps}>
        <Map
          mapId={'9a918fb0ecc441c5'}
          defaultZoom={initialCamera.zoom}
          defaultCenter={currentPosition || defaultCenter}
          gestureHandling={'greedy'}
          reuseMaps
          zoomControl
          disableDefaultUI
          style={{ width: '100%', height: '600px' }}
        >
          {!isLoadingObjects &&
            yesObjects?.map((object) => (
              <MapMarker key={object.uuid} object={object} />
            ))}
        </Map>

        {!hide_custom_map_control && (
          <CustomMapControl
            controlPosition={ControlPosition.TOP_LEFT}
            onPlaceSelect={handleSelectPlace}
          />
        )}

        <MapHandler markers={yesObjects} place={selectedPlace} />

        {isNoDataFound && <NoDataFound message={messageNoDataFound} />}

        {isLoading && (
          <div className='loading-place-map'>
            <YesLoading />
          </div>
        )}
      </div>
    </APIProvider>
  )
}

export default MapContainer
