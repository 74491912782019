import styled from 'styled-components'

const gridTemplateAreas = (
  noImage: boolean,
  titleOnTop: boolean,
  $reverse: boolean
) => {
  if (noImage) {
    return `'title' 'content'`
  }

  if (titleOnTop) {
    return $reverse
      ? `'title title' 'content image'`
      : `'title title' 'image content'`
  } else {
    return $reverse
      ? `'title image' 'content image'`
      : `'image title' 'image content'`
  }
}

export const MainContainer = styled.div<{
  noImage: boolean
  titleOnTop: boolean
  $reverse: boolean
}>`
  display: grid;
  gap: 24px;

  @media (min-width: 768px) {
    grid-template-rows: auto auto;
    grid-template-areas: ${({ noImage, titleOnTop, $reverse }) =>
      gridTemplateAreas(noImage, titleOnTop, $reverse)};
  }
`

export const ArticleTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blackText};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-align: justify;

  @media (min-width: 768px) {
    grid-area: title;
    align-self: end;
    font-size: 32px;
    line-height: 48px; /* 150% */
  }
`

export const ArticleImage = styled.img`
  width: 100%;

  @media (min-width: 768px) {
    grid-area: image;
    align-self: center;
    width: 432px;
  }
`

export const ContentContainer = styled.div`
  display: grid;
  row-gap: 16px;
  font-family: Barlow;

  @media (min-width: 768px) {
    grid-area: content;
    align-self: start;
  }
`

export const ArticleContent = styled.div<{ $textColor?: string }>`
  color: ${({ theme, $textColor }) => $textColor || theme.colors.blackText};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
`

export const ArticleImageDocument = styled.div`
  width: 100%;

  > img {
    border-radius: 8px;
  }

  @media (min-width: 768px) {
    grid-area: image;
    align-self: center;
    width: 432px;
  }
`
