import { Text } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import FiltersIcon from '../../icons/FiltersIcon'
import { FilterContainer, FilterSubtitleContainer } from './styles'

type MapFilterProps = {
  referencePeriodText: string
}

const MapFilter = ({ referencePeriodText }: MapFilterProps) => {
  const { t } = useTranslation()
  return (
    <FilterContainer>
      <Text variant='content-emphasis-04'>
        {t('filterable-map.data-origin')}
      </Text>
      <FilterSubtitleContainer>
        <FiltersIcon />
        <Text variant='content-03'>{referencePeriodText}</Text>
      </FilterSubtitleContainer>
    </FilterContainer>
  )
}

export default MapFilter
