import { createContext, useCallback, useContext } from 'react'
import { SiteStateKey } from './siteStateKey'

type Updater<T> = (prevState: T) => T
type SetStateAction<T> = T | Updater<T>

type SiteStateContextType = {
  items: { [key: string]: unknown }
  setSiteStateItem: <T>(key: string, value: SetStateAction<T>) => void
}

export const SiteStateContext = createContext<SiteStateContextType | undefined>(
  undefined
)

export default function useSiteState<ItemType>(
  stateItemKey: SiteStateKey,
  elementUuid?: string
): [ItemType, (value: SetStateAction<ItemType>) => void] {
  const context = useContext(SiteStateContext)

  const elementKey = elementUuid
    ? `${stateItemKey}-${elementUuid}`
    : stateItemKey

  if (context === undefined) {
    throw new Error('useSiteState must be used within a SiteStateProvider')
  }

  const { items, setSiteStateItem } = context
  const item = items[elementKey] as ItemType

  const setItem = useCallback(
    (setStateAction: SetStateAction<ItemType>) => {
      setSiteStateItem(elementKey, setStateAction)
      if (elementUuid) {
        // TODO: Quando todos os elementos implementarem um uuid e o elementUuid for obrigatório, remover o if
        setSiteStateItem(stateItemKey, setStateAction)
      }
    },
    [elementKey, elementUuid, stateItemKey, setSiteStateItem]
  )

  return [item, setItem]
}
