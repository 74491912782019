import { StatusObject, Text, SkeletonDots } from '@yes.technology/react-toolkit'
import React from 'react'
import { useTranslation } from 'react-i18n-lite'
import {
  ItemContainer,
  OrderTableBody,
  OrderTableContainer,
  OrderTableSectionHeader,
  OrderTableValue,
  WhiteSkeleton
} from './OrderTable.styles'
import OrderTableActions from './OrderTableActions'
import { OrderType } from './TransferOrderCard'
import { Line } from './TransferOrderCard.styles'
import { GenericOrder, ProductCombo } from './types'
import { useCallback } from 'react'

type OrderTableProps = {
  title: string
  orders: GenericOrder[]
  productCombos: Record<string, ProductCombo>
  status: StatusObject
  type: OrderType
  isLoading?: boolean
  isLoadingDetails?: boolean
}

type OrderTableItemProps = {
  order: GenericOrder
  productCombos: Record<string, ProductCombo>
}

function renderProductComboSummary(productCombo: ProductCombo) {
  return (
    `${productCombo.order || '-'} | ${productCombo.productmodel?.[0]?.des} | ` +
    ` ${productCombo.quantity} | ${productCombo.measurementunit?.[0]?.des}`
  )
}

function OrderTableItem({ order, productCombos }: OrderTableItemProps) {
  return (
    <>
      {order?.productcombo?.map((product) => (
        <OrderTableValue key={product.uuid}>
          {productCombos[product.uuid] &&
            renderProductComboSummary(productCombos[product.uuid])}
        </OrderTableValue>
      ))}
    </>
  )
}

export default function OrderTable({
  title,
  status,
  type,
  orders,
  productCombos,
  isLoading,
  isLoadingDetails
}: OrderTableProps) {
  const { t } = useTranslation()

  const ItemsSkeletons = useCallback(
    () => (
      <>
        <ItemContainer>
          <SkeletonDots size={2} />
        </ItemContainer>
        <ItemContainer>
          <SkeletonDots size={2} />
        </ItemContainer>
      </>
    ),
    []
  )

  const OrderTableItems = useCallback(
    () => (
      <>
        {orders?.map((order) => (
          <React.Fragment key={order.uuid}>
            <OrderTableItem order={order} productCombos={productCombos} />
            <OrderTableActions order={order} status={status} type={type} />
          </React.Fragment>
        ))}
      </>
    ),
    [orders, productCombos, status, type]
  )

  if (isLoading)
    return (
      <OrderTableContainer level={1} spacingClass='doubleXSmall'>
        <OrderTableSectionHeader>
          <WhiteSkeleton size={2} />
        </OrderTableSectionHeader>

        <Text variant='label-required-02'>
          <SkeletonDots size={2} />
        </Text>

        <Line />

        <OrderTableBody>
          <ItemsSkeletons />
        </OrderTableBody>
      </OrderTableContainer>
    )

  return (
    <OrderTableContainer level={1} spacingClass='doubleXSmall'>
      <OrderTableSectionHeader>{title}</OrderTableSectionHeader>

      <Text variant='label-required-02'>
        {t('transfer-order-card.order-table-header')}
      </Text>

      <Line />

      <OrderTableBody>
        <OrderTableBody>
          {isLoadingDetails ? <ItemsSkeletons /> : <OrderTableItems />}
        </OrderTableBody>
      </OrderTableBody>
    </OrderTableContainer>
  )
}
