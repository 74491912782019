import { Array, Optional, Record, String } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

type ImageType = {
  url: string
  alt: string
}

type SlideType = {
  des: string
  complement: string
  image: {
    desktop: ImageType
    mobile: ImageType
  }
}

export type AnimationSliderType = {
  slides: SlideType[]
  subtitles: string[]
  starterPopup: {
    des: string
    complement: string
  }
  color?: string
  textColor?: string
}

export type AnimationSliderContainerType = {
  props: {
    slides: SlideType[]
    subtitles: string[]
    starterPopup: {
      des: string
      complement: string
    }
    color?: string
    textColor?: string
    styleMap?: StyleMap
  }
}

export const AnimationSliderContainerTypeGuard = Record({
  slides: Array(
    Record({
      des: String,
      complement: String,
      image: Record({
        desktop: Record({
          url: String,
          alt: String
        }),
        mobile: Record({
          url: String,
          alt: String
        })
      })
    })
  ),
  subtitles: Array(String),
  starterPopup: Record({
    des: String,
    complement: String
  }),
  color: Optional(String),
  textColor: Optional(String)
})
