import styled from 'styled-components'

type MapContainerProps = {
  disabled?: boolean
  zoomState: number
  disableMapInteractions?: boolean
}

export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const MapContainer = styled.div<MapContainerProps>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;

  display: flex;
  justify-content: center;

  svg {
    max-width: 840px;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  }

  path {
    cursor: ${(props) =>
      props.disabled || props.disableMapInteractions ? 'default' : 'pointer'};
  }
`
export const MapActionsContainer = styled.div`
  position: absolute;
  right: 12px;
  bottom: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  disply: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 12px;
  width: 26px;
  background-color: white;
`

export const ActionButton = styled.button`
  background-color: white;
  color: #666666;
  font-size: 24px;
  font-weight: 700;
  border: 0;
  outline: 0;
  border-radius: inherit;
  width: 100%;
  text-align: center;
  padding: 0;

  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`

export const Divider = styled.hr`
  height: 1px;
  margin: 2px 0;
  background-color: #dadce0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 8px;
  }
`
