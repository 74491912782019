import { ArticleContainerProps } from './ArticleContainer.types'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { StyledContainer } from './styles'
import Article from './Article/Article'
import { Col, Row } from '@yes.technology/react-toolkit'
import { StyledGrid } from 'shared/style/grid'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const ArticleContainer = ({ props }: ArticleContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)

  return (
    <StyledGrid style={styleProps}>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={12}>
          <StyledContainer>
            <Article
              {...props}
              des={props.des && <SafeHtmlContent html={props.des} />}
              complement={<SafeHtmlContent html={props.complement} />}
              buttons={props.buttons}
              textColor={props.textColor}
            />
          </StyledContainer>
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default ArticleContainer
