import { uniq } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  FilterableMapProps,
  FilteredItemsProps,
  MapStatesRef,
  TabType
} from './FilterableMap.types'
import BrazilMapSelectable from './components/BrazilMapSelectable/BrazilMapSelectable'
import FilteredObjects from './components/FilteredObjects/FilteredObjects'
import { ContentContainer } from './styles'
import brazilianStatesNameToAcronymMap from './utils/brazilianStatesNameToAcronymMap'

const transformState = (state: string) => state.replace('BR-', '').toUpperCase()

const FilterableMap = ({ props }: FilterableMapProps) => {
  const {
    items,
    selectedStateColor = '#3E6F6B',
    defaultStateColor = '#F1F1F1',
    strokeColor = '#cfcfcf',
    selectedStateStrokeColor = '#ffffff',
    mobileDefaultStrokeColor = '#666666',
    mobileSelectedStateStrokeColor = '#ffffff',
    selectionMode = 'single',
    actionTarget = '_blank',
    referencePeriodText,
    returnAllResultsForFederalDomain = false,
    initialSelectedState = 'BR-SP'
  } = props

  const [selectedStates, setSelectedStates] = useState<string[]>([])

  const [filteredItems, setFilteredItems] =
    useState<FilteredItemsProps[]>(items)

  const [mode, setMode] = useState<TabType>('state')

  const mapRef = useRef<MapStatesRef>({ getAllStates: () => [] })

  const initialListRef = useRef<FilteredItemsProps[]>([])

  const mapContainerRef = useRef<HTMLDivElement>(null)

  const storedStatesRef = useRef<string[]>([])

  const filteredObjectsRef = useRef<HTMLDivElement>(null)

  const onChangeStates = useCallback(
    (state: string, shouldScrollToObjectsList = true) => {
      if (mode === 'federal') return

      let selectedStates: string[] = []

      const getSelectedStates = (currentSelectedStates: string[]) => {
        const [selectedState] = currentSelectedStates
        if (selectionMode === 'single') {
          if (state === selectedState) {
            return []
          }
          return [state]
        } else if (currentSelectedStates.includes(state)) {
          return currentSelectedStates.filter((s) => s !== state)
        }
        return uniq([...currentSelectedStates, state])
      }

      setSelectedStates((prev) => {
        selectedStates = getSelectedStates(prev)

        storedStatesRef.current = [...selectedStates]

        const selectedStatesFormatted = [...selectedStates].map((state) =>
          transformState(state)
        )

        const filterdObjects = initialListRef.current.filter(({ obs }) => {
          const stateAcronym = brazilianStatesNameToAcronymMap[obs]
          return selectedStatesFormatted.includes(stateAcronym)
        })

        setFilteredItems(filterdObjects)

        if (shouldScrollToObjectsList && selectedStates.length > 0) {
          filteredObjectsRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }

        return selectedStates
      })
    },
    [mode, selectionMode]
  )

  const getFederalDomainItems = useCallback((items: FilteredItemsProps[]) => {
    if (returnAllResultsForFederalDomain) {
      return items
    }
    return items.filter(({ obs }) => obs === 'Brasil')
  }, [])

  useEffect(() => {
    if (mode !== 'federal') {
      return
    }

    const { getAllStates } = mapRef.current

    const allStates = getAllStates()

    setSelectedStates(allStates)
  }, [mode])

  useEffect(() => {
    if (items?.length > 0 && !initialListRef.current.length) {
      initialListRef.current = items
      if (mode === 'state' && initialSelectedState) {
        onChangeStates(initialSelectedState, false)
      }
    }
  }, [
    items,
    mode,
    initialSelectedState,
    getFederalDomainItems,
    onChangeStates,
    returnAllResultsForFederalDomain
  ])

  const onTabChange = useCallback(
    (newTab: TabType) => {
      setMode(newTab)
      if (newTab === 'federal') {
        setFilteredItems(getFederalDomainItems(initialListRef.current))
      } else {
        setSelectedStates([])
        const selectedStates = storedStatesRef.current
        if (selectedStates.length > 0) {
          if (selectionMode === 'single') {
            const [selectedState] = selectedStates
            onChangeStates(selectedState, false)
          } else {
            selectedStates.forEach((state) => {
              onChangeStates(state, false)
            })
          }
        }

        mapContainerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    },
    [getFederalDomainItems, selectionMode, initialSelectedState]
  )

  return (
    <>
      <ContentContainer ref={mapContainerRef}>
        <BrazilMapSelectable
          selectedStates={selectedStates}
          onChange={onChangeStates}
          disableMapInteractions={mode === 'federal'}
          displayLabels={mode === 'state'}
          selectedStateColor={selectedStateColor}
          defaultStateColor={defaultStateColor}
          strokeColor={strokeColor}
          selectedStateStrokeColor={selectedStateStrokeColor}
          mobileDefaultStrokeColor={mobileDefaultStrokeColor}
          mobileSelectedStateStrokeColor={mobileSelectedStateStrokeColor}
          ref={mapRef}
          referencePeriodText={referencePeriodText}
          displayZoomControls={mode === 'state'}
        />
      </ContentContainer>
      <FilteredObjects
        ref={filteredObjectsRef}
        objects={filteredItems}
        onTabChange={onTabChange}
        selectedStates={selectedStates}
        actionColor={selectedStateColor}
        target={actionTarget}
      />
    </>
  )
}

export default FilterableMap
