import { useTranslation } from 'react-i18n-lite'

import { YesObject } from '@yes.technology/react-toolkit'

import ImageDocument from 'component/ImageDocument/ImageDocument'

import { useGeolocation } from 'shared/hooks'

import { DirectionsButton } from './DirectionsButton'

import {
  Address,
  CardContent,
  Content,
  Grupoalvo,
  ImageContainer,
  Title,
  WorkingSchedule,
  InfoLabel,
  InfoText
} from './MapContainer.styles'

import { MarkerProps } from './MapContainer.types'

const logoCpec = window.cloudfrontUrl + '/static-files/cpec-logo.svg'

export const DeliveryPointInfoWindow = ({ object }: MarkerProps) => {
  const { currentPosition } = useGeolocation()
  const { t } = useTranslation()

  const grupoalvo = getName(object?.gruposalvos as YesObject[])
  const addressObj = formatAddress(object.address?.[0] as YesObject)
  const cityName = getName(object?.cidade as YesObject[])
  const stateName = getState(object?.estado as YesObject[])

  const workingSchedule = object.workingschedule as YesObject[]
  const produtos = object?.produtos as YesObject[]

  return (
    <CardContent>
      <ImageContainer>
        <ImageDocument documents={object.documento || logoCpec} height='70' />
      </ImageContainer>
      <Content>
        <Title>{object.des}</Title>
        {/* TODO: “Modalidade de Associação” */}
        {grupoalvo && <Grupoalvo>{grupoalvo}</Grupoalvo>}
        <Address>
          {formatFullAddress(
            {
              des: addressObj.des,
              number: addressObj?.number as string,
              complement: addressObj.complement,
              neighborhood: addressObj.neighborhood as string,
              zipCode: addressObj.zipCode as string
            },
            cityName,
            stateName
          )}
        </Address>
        {workingSchedule && (
          <WorkingSchedule>
            {workingSchedule?.map((schedule: YesObject) => (
              <div key={schedule.uuid}>{schedule.des}</div>
            ))}
          </WorkingSchedule>
        )}
        {produtos && (
          <>
            <InfoLabel>
              {t('map-container.collected-products-categories')}:
            </InfoLabel>
            <InfoText>{produtos.map(({ des }) => des).join(', ')}</InfoText>
          </>
        )}
        {currentPosition && (
          <DirectionsButton
            origin={currentPosition}
            destination={{
              lat: addressObj.latitude,
              lng: addressObj.longitude
            }}
          />
        )}
      </Content>
    </CardContent>
  )
}

const getName = (obj: YesObject[] | undefined) =>
  typeof obj === 'object' ? obj[0].des : ''

const getState = (obj: YesObject[] | undefined) =>
  typeof obj === 'object' ? (obj[0].sigla as string) : ''

const formatAddress = (address: YesObject) => {
  if (!address) {
    return {
      des: '',
      number: '',
      complement: '',
      neighborhood: '',
      zipCode: '',
      latitude: 0,
      longitude: 0
    }
  }

  return {
    des: address.des,
    number: address.number,
    complement: address.complement,
    neighborhood: address.neighborhood,
    zipCode: address.zip_code,
    latitude: parseFloat(address.latitude as string),
    longitude: parseFloat(address.longitude as string)
  }
}

interface AddressObj {
  des: string
  number?: string
  complement?: string
  neighborhood?: string
  zipCode?: string
}

const formatFullAddress = (
  addressObj: AddressObj,
  cityName: string,
  stateName: string
) => (
  <>
    {addressObj?.des}
    {addressObj?.number ? `, ${addressObj?.number}` : ''}
    {addressObj?.complement ? ` - ${addressObj?.complement}` : ''}
    {addressObj?.neighborhood ? ` - ${addressObj?.neighborhood}` : ''}
    {cityName && stateName ? `, ${cityName} - ${stateName}` : ''}
    {addressObj?.zipCode ? `, ${addressObj?.zipCode}` : ''}
  </>
)
