import { useCallback } from 'react'

import {
  NavigationSlider,
  Pagination as TPagination
} from '@yes.technology/react-toolkit'

type PaginationProps = {
  className?: string
  pagination?: TPagination
  setCurrentPage: (page: number) => void
  hierarchyLevel?: 'level_1' | 'level_2' | 'level_3'
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  currentValueEditable?: boolean
  showTotalPages?: boolean
  sectionText?: string
}

export default function Pagination({
  className,
  pagination,
  setCurrentPage,
  hierarchyLevel = 'level_2',
  navigationSliderBackgroundColor,
  navigationSliderColor,
  currentValueEditable = false,
  showTotalPages = false,
  sectionText = 'Page'
}: PaginationProps) {
  const pageSize = pagination?.limit || 10

  const offset = pagination?.offset || 0
  const currentPage = offset / pageSize + 1 || 1

  const onPageChange = useCallback(
    (nextPage: number) => {
      const isPageValid =
        nextPage > 0 && (pagination?.hasNext || pagination?.hasPrevious)
      isPageValid && setCurrentPage(nextPage)
    },
    [pagination?.hasNext, pagination?.hasPrevious, setCurrentPage]
  )

  if (!pagination?.hasPrevious && !pagination?.hasNext) {
    return null
  }

  return (
    <NavigationSlider
      currentPosition={currentPage}
      first={1}
      total={1}
      hierarchyLevel={hierarchyLevel}
      textForCurrent={sectionText}
      afterClick={onPageChange}
      hasPreviousPage={pagination?.hasPrevious}
      hasNextPage={pagination?.hasNext}
      className={className}
      arrowOrientationButtons='verticalArrows'
      heightClass='small'
      showTotalPages={showTotalPages}
      backgroundColor={navigationSliderBackgroundColor}
      color={navigationSliderColor}
      currentValueEditable={currentValueEditable}
    />
  )
}
