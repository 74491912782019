import {
  CollapsibleCard,
  LinkButton,
  YesObject
} from '@yes.technology/react-toolkit'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18n-lite'
import useStatusByCode from 'status/shared/hooks/useStatusByCode'
import OrderTable from './OrderTable'
import { CardTitle, InformationalInput, Line } from './TransferOrderCard.styles'
import { GenericOrder, TransferOrderCardProps } from './types'
import useTransferOrderDetail from './useTransferOrderDetail'

export enum OrderType {
  DELIVERY = 'DELIVERY',
  RECEIPT = 'RECEIPT',
  PASS = 'PASS',
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
  CLASSIFICATION = 'CLASSIFICATION'
}

export default function TransferOrderCard({
  transferOrder
}: TransferOrderCardProps) {
  const { t } = useTranslation()

  const { sid, sender, receiver, uuid_status: statusCode, uuid } = transferOrder

  const { status } = useStatusByCode({
    objectclassUuid: window.uuidObjectclassTransferorder,
    statusCode
  })

  const {
    handoffOrders,
    receiptOrders,
    passOrders,
    loadingOrders,
    unloadingOrders,
    classificationOrders,
    productsCombosDetailedMap,
    isLoadingDetails
  } = useTransferOrderDetail({ transferOrder })

  const renderOrderTable = useCallback(
    (
      orders: YesObject[],
      type: OrderType,
      titleKey: string,
      isLoading: boolean
    ) => {
      if (!orders?.length) return null

      const title = t(titleKey)

      return (
        <OrderTable
          title={title}
          orders={orders as GenericOrder[]}
          productCombos={productsCombosDetailedMap}
          status={status}
          type={type}
          isLoading={isLoading}
          isLoadingDetails={isLoadingDetails}
        />
      )
    },
    [productsCombosDetailedMap, status, t, isLoadingDetails]
  )

  return (
    <CollapsibleCard
      heightClass='doubleXSmall'
      headerColor='white'
      leftHeaderElement={
        <CardTitle>
          {statusCode} | {status?.des}
        </CardTitle>
      }
      rightHeaderElement={<CardTitle>{sid as string}</CardTitle>}
    >
      <Line />

      <InformationalInput
        label={t('transfer-order-card.shipper')}
        value={sender?.[0]?.des}
      />

      <InformationalInput
        label={t('transfer-order-card.receiver')}
        value={receiver?.[0]?.des}
      />

      {renderOrderTable(
        handoffOrders.yesObjects,
        OrderType.DELIVERY,
        'transfer-order-card.delivery-order',
        handoffOrders.isLoading
      )}
      {renderOrderTable(
        receiptOrders.yesObjects,
        OrderType.RECEIPT,
        'transfer-order-card.receipt-order',
        receiptOrders.isLoading
      )}
      {renderOrderTable(
        passOrders.yesObjects,
        OrderType.PASS,
        'transfer-order-card.pass-order',
        passOrders.isLoading
      )}
      {renderOrderTable(
        loadingOrders.yesObjects,
        OrderType.LOADING,
        'transfer-order-card.loading-order',
        loadingOrders.isLoading
      )}
      {renderOrderTable(
        unloadingOrders.yesObjects,
        OrderType.UNLOADING,
        'transfer-order-card.unloading-order',
        unloadingOrders.isLoading
      )}
      {renderOrderTable(
        classificationOrders.yesObjects,
        OrderType.CLASSIFICATION,
        'transfer-order-card.classification-order',
        classificationOrders.isLoading
      )}

      <LinkButton
        href={`/${window.objectDetailsSitemodelUuid}?uuid_objectclass=${window.uuidObjectclassTransferorder}&object-uuid=${uuid}`}
        variant='secondary'
      >
        {t('transfer-order-card.view')}
      </LinkButton>
    </CollapsibleCard>
  )
}
