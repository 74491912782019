import DynamicInfoCardHighlight from './DynamicInfoCardHighlight'

export {
  type ButtonPosition,
  type ComplementPosition,
  type ImagePosition,
  type ImageFormat
} from './DynamicInforCardHighlight.types'

export default DynamicInfoCardHighlight
