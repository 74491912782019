import { isEmbedded } from '@yes.technology/react-toolkit'
import { loginSucceeded } from 'authentication/Login/ducks/authentication'
import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { IframeAttributes } from 'shared/components/Iframe'

type EventDataMessage = {
  height?: string
  type?: string
  url?: string
  token?: string
  expirationDate?: number
}

type Props = {
  origin: string
  src: string
  title?: string
  allow?: string
  initialHeight?: string
}

const useIframe = ({
  origin,
  src,
  title = 'Masterdata',
  allow = 'camera;geolocation',
  initialHeight = '800px' //  TODO: Alterar para 100vh quando a workflow engine passar a enviar o height por mensagem
}: Props) => {
  const [height, setHeight] = useState(initialHeight)
  const [iframeAttributes, setIframeAttributes] = useState<
    IframeAttributes | undefined
  >()

  const dispatch = useDispatch()

  const receiveMessage = useCallback(
    (event: MessageEvent<EventDataMessage>) => {
      // TODO: Improve iframe recognition in case of two different iframes with same origin (URL =/= Origin)
      if (!origin.startsWith(event.origin)) return false
      const { height, type } = event.data
      if (height) {
        setHeight(`${Math.ceil(Number(height) + 50)}`)
      }
      if (type === 'redirect') {
        if (isEmbedded()) {
          postMessage(event.data)
        } else {
          if (event.data.url) window.location.href = event.data.url
        }
      }

      if (type === 'updateAuthToken') {
        const { token, expirationDate } = event.data
        if (!token || !expirationDate) return

        const decoded = jwtDecode<{ email: string }>(token)
        dispatch(
          loginSucceeded(decoded.email, {
            token,
            expiration_date: expirationDate,
            tokenType: 'private'
          })
        )

        if (isEmbedded()) {
          postMessage(event.data)
        }
      }
    },
    [origin] // postMessage foi implementado sem useCallback por isso não foi adicionado aqui
  )

  useEffect(() => {
    setIframeAttributes({
      src,
      height,
      title,
      allow
    })
  }, [allow, height, src, title])

  return {
    iframeAttributes,
    receiveMessage
  }
}

export default useIframe
