import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { SeparatorProps } from './Separator.type'
import { StyledSeparator } from './styles'

const Separator = ({ props }: SeparatorProps) => {
  const { styleMap } = props

  const styles = useStyleMaps(styleMap)

  return <StyledSeparator style={styles} />
}

export default Separator
