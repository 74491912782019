import { YesObject } from '@yes.technology/react-toolkit'
import { ProductCombo, TransferOrder } from './types'
import { useFetchYesObjects } from 'yesObject/shared'
import { useMemo } from 'react'

type UseTransferOrderProps = {
  transferOrder: TransferOrder
}

const useOrderObjects = (orders: YesObject[] = [], enabled = !!orders.length) =>
  useFetchYesObjects({
    filterBody: {
      objectclass: orders?.[0]?.uuid_Objectclass,
      query: { $or: orders.map((item) => ({ uuid: { $eq: item.uuid } })) },
      skip_relations: false
    },
    enabled
  })

export default function useTransferOrderDetail({
  transferOrder
}: UseTransferOrderProps) {
  const {
    handofforder_transferorder,
    receiptorder_transferorder,
    passorder_transferorder,
    loadingorder_transferorder,
    unloadingorder_transferorder,
    classificationorder
  } = transferOrder

  const handoffObjectsResponse = useOrderObjects(handofforder_transferorder)
  const receiptObjectsResponse = useOrderObjects(receiptorder_transferorder)
  const passObjectsResponse = useOrderObjects(passorder_transferorder)
  const loadingObjectsResponse = useOrderObjects(loadingorder_transferorder)
  const unloadingObjectsResponse = useOrderObjects(unloadingorder_transferorder)
  const classificationObjectsResponse = useOrderObjects(classificationorder)

  const {
    yesObjects: handoffOrders,
    isFetching: isHandoffFetching,
    isFetched: isHandoffFetched
  } = handoffObjectsResponse
  const {
    yesObjects: receiptOrders,
    isFetching: isReceiptFetching,
    isFetched: isReceiptFetched
  } = receiptObjectsResponse
  const {
    yesObjects: passOrders,
    isFetching: isPassFetching,
    isFetched: isPassFetched
  } = passObjectsResponse
  const {
    yesObjects: loadingOrders,
    isFetching: isLoadingFetching,
    isFetched: isLoadingFetched
  } = loadingObjectsResponse
  const {
    yesObjects: unloadingOrders,
    isFetching: isUnloadingFetching,
    isFetched: isUnloadingFetched
  } = unloadingObjectsResponse
  const {
    yesObjects: classificationOrders,
    isFetching: isClassificationFetching,
    isFetched: isClassificationFetched
  } = classificationObjectsResponse

  const productCombos = useMemo(
    () =>
      [
        handoffOrders,
        receiptOrders,
        passOrders,
        loadingOrders,
        unloadingOrders,
        classificationOrders
      ]
        .map((orders) => orders.map((order) => order.productcombo))
        .flat(2)
        .filter((productCombo) => productCombo) as YesObject[],
    [
      classificationOrders,
      handoffOrders,
      loadingOrders,
      passOrders,
      receiptOrders,
      unloadingOrders
    ]
  )

  const canFetchProductsCombo = [
    isHandoffFetched ||
      (handofforder_transferorder && !handofforder_transferorder.length),
    isReceiptFetched ||
      (receiptorder_transferorder && !receiptorder_transferorder.length),
    isPassFetched ||
      (passorder_transferorder && !passorder_transferorder.length),
    isLoadingFetched ||
      (loadingorder_transferorder && !loadingorder_transferorder.length),
    isUnloadingFetched ||
      (unloadingorder_transferorder && !unloadingorder_transferorder.length),
    isClassificationFetched ||
      (classificationorder && !classificationorder.length)
  ]
    .filter((bool) => bool !== undefined)
    .every(Boolean)

  const {
    yesObjects: productsCombosDetailed,
    isFetching: isFetchingProductCombos
  } = useOrderObjects(productCombos, canFetchProductsCombo)

  const productsCombosDetailedMap = useMemo(() => {
    const map: Record<string, YesObject> = {}

    productsCombosDetailed.forEach(
      (productCombo) => (map[productCombo.uuid] = productCombo)
    )

    return map as Record<string, ProductCombo>
  }, [productsCombosDetailed])

  const isLoadingDetails =
    isHandoffFetching ||
    isReceiptFetching ||
    isPassFetching ||
    isLoadingFetching ||
    isUnloadingFetching ||
    isClassificationFetching ||
    isFetchingProductCombos

  return {
    handoffOrders: handoffObjectsResponse,
    receiptOrders: receiptObjectsResponse,
    passOrders: passObjectsResponse,
    loadingOrders: loadingObjectsResponse,
    unloadingOrders: unloadingObjectsResponse,
    classificationOrders: classificationObjectsResponse,
    productsCombosDetailedMap,
    isLoadingDetails
  }
}
