import { LinkButton, StatusObject } from '@yes.technology/react-toolkit'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { OrderTableBody } from './OrderTable.styles'
import { OrderType } from './TransferOrderCard'
import { GenericOrder } from './types'

type OrderTableActionsType = {
  status: StatusObject
  type: OrderType
  order: GenericOrder
}

type ActionObject = {
  label: string
  url: string
}

const OrderTableActions = ({ status, type, order }: OrderTableActionsType) => {
  const { t } = useTranslation()

  const actionBaseUrl = `${window.workflowmodelExecutorUuid}?workflowmodelUuid=`

  const actions = useMemo(() => {
    const tableActions: ActionObject[] = []

    const statusCode = status?.code

    const workflowUuid = order?.workflow?.[0]?.uuid || ''

    switch (type) {
      case OrderType.RECEIPT:
        if (statusCode === window.receiptOrderStartingStatusCode) {
          tableActions.push({
            label: t('transfer-order-card.actions.receive'),
            url: `${actionBaseUrl}${workflowUuid}`
          })
        }
        break
      case OrderType.DELIVERY:
        if (statusCode === window.handoffOrderStartingStatusCode) {
          tableActions.push({
            label: t('transfer-order-card.actions.deliver'),
            url: `${actionBaseUrl}${workflowUuid}`
          })
        }
        break
      default:
        return null
    }

    return tableActions
  }, [status?.des, type, order])

  if (!status || !type || !actions?.length) return null

  return (
    <OrderTableBody>
      {actions.map(({ label, url }, index) => {
        return (
          <LinkButton
            href={url}
            variant='secondary'
            key={index}
            heightClass='small'
          >
            {label}
          </LinkButton>
        )
      })}
    </OrderTableBody>
  )
}

export default OrderTableActions
