import { Col, LinkButton, Row } from '@yes.technology/react-toolkit'
import React from 'react'
import { StyledGrid } from 'shared/style/grid'
import { ActionButtonProps } from './ActionButton.types'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

function ActionButton({ props }: ActionButtonProps) {
  const styleProps = useStyleMaps(props.styleMap)
  return (
    <StyledGrid style={styleProps}>
      <Row className='justify-content-center'>
        <Col md={12} lg={6}>
          <LinkButton {...props} />
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default ActionButton
