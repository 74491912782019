import { SaF } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export type SaFChartProps = {
  data: React.ComponentProps<typeof SaF>
  title: string
}

const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`

const Title = styled.h2`
  z-index: 1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
`

const SaFChart = ({ data, title }: SaFChartProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <SaF columns={data.columns} data={data.data} />
    </Container>
  )
}

export default SaFChart
