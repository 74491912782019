import { NavigationSlider } from '@yes.technology/react-toolkit'
import useTableContext from '../hooks/useTableContext'
import { NavigationSliderWrapper } from './style'

export type ColumnsNavigationProps = {
  hierarchyLevel?: 'level_1' | 'level_2' | 'level_3'
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
}

const ColumnsNavigation = ({
  hierarchyLevel = 'level_2',
  navigationSliderBackgroundColor,
  navigationSliderColor
}: ColumnsNavigationProps) => {
  const {
    currentColumnIndex,
    totalColumns,
    onChangeColumnsPage,
    columnsNames,
    amountOfFixedColumns,
    amountOfActionColumns
  } = useTableContext()

  const handleAfterClick = (n: number) => {
    onChangeColumnsPage(n)
  }

  const shouldHideColumns =
    totalColumns - (columnsNames.length + amountOfActionColumns) <= 0

  const currentIndex = currentColumnIndex + amountOfFixedColumns

  if (shouldHideColumns) return null

  return (
    <NavigationSliderWrapper>
      <NavigationSlider
        currentPosition={currentIndex}
        first={1}
        total={totalColumns}
        hierarchyLevel={hierarchyLevel}
        textForCurrent='Colunas'
        afterClick={handleAfterClick}
        heightClass='small'
        backgroundColor={navigationSliderBackgroundColor}
        color={navigationSliderColor}
        currentValueEditable
        doubleArrowhead
      />
    </NavigationSliderWrapper>
  )
}

export default ColumnsNavigation
