import { HierarchyCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'
import { TitlePosition } from './SummaryDetailsCarouselContainer.types'

export const StyledHierarchyCard = styled(HierarchyCard)`
  position: relative;

  > div:first-child {
    padding: 16px 16px 16px 16px;
  }

  @media (min-width: 768px) {
    > div:first-child {
      padding: 64px;
    }
  }
`

export const Title = styled.h2<{ position?: TitlePosition }>`
  color: #303032;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  margin: ${({ position = 'top' }) =>
    position === 'top' ? '0px 0px 8px 0px' : '0'};

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
    margin: ${({ position = 'top' }) =>
      position === 'top' ? '0px 0px 36px 0px' : '0'};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 64px;
  }
`

export const Image = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  @media (min-width: 768px) {
    max-width: 800px;
    min-height: 390px;
  }
`

export const Summary = styled.div`
  flex: 1;
  color: #303032;
  text-align: justify;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    width: 50%;

    &:only-child {
      width: 100%;
    }

    font-size: 24px;
    line-height: 32px;
    gap: 26px;
  }
`

export const Navigation = styled.div`
  margin: 60px auto 0 auto;
  display: flex;
  justify-content: center;
`

export const DetailsSectionContainer = styled.div`
  width: 100%;
  margin: 32px auto 0 auto;

  @media (min-width: 768px) {
    max-width: 1920px;
    margin: 60px auto 0 auto;
  }
`
