import { YesObject } from '@yes.technology/react-toolkit'
import { Record, Unknown, Array, Optional } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type ActionStepsProps = {
  props: {
    items: YesObject[]
    styleMap?: StyleMap
  }
}

export const ActionStepsTypeGuard = Record({
  items: Array(Unknown),
  styleMap: Optional(Unknown)
})
